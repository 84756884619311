
import {user,instance,web3,contractAddress} from '../App';
import Dogsale from "./Dogsale";
import axios from "axios"
import $ from 'jquery';
import ReactDOM from 'react-dom/client';
import { useState,useEffect } from 'react'


var username = $("#user-name").html();
function Profile() {
var [tokensOfOwner, setTokensOfOwner] = useState([]); 
var [geneSequences, setGeneSequences] = useState([]); 
var [geneSequenceandID, setGeneSequenceandID] = useState([]); ;

const [infoget,setinfoget] = useState([]);
   

    async function getSubmissions(){
        axios.get('https://blockchainpets.abdulhananjavaid.com/bcpapi/userinfo.php').then(function(response){
            console.log(response.data);
            setinfoget(response.data);
            response.data.forEach(function(person){
              console.log(person);
              if(person.address == user){
                $("#user-name").html(person.fullname)
                $("#user-email").html(person.email)
               
              
              }
            })
             
          })
          
    }

useEffect(()=>{
  
  async function getUserNFTs(){
    await getSubmissions()
    if(instance !=null){
      tokensOfOwner = await instance.methods.tokensOfOwner(user).call()
      // const totalSupply = await instance.methods.totalSupply().call()
      geneSequences = [];
      for(const token of tokensOfOwner){
        geneSequences.push(await instance.methods.getDog(token).call())
      }
      geneSequenceandID= [];

      for(var i = 0; i < tokensOfOwner.length; i++){
        geneSequenceandID.push(geneSequences[i].geneSequence + tokensOfOwner[i] + "-" + geneSequences[i].generation);
        
      }
      setTokensOfOwner(tokensOfOwner)
      setGeneSequences(geneSequences)
      setGeneSequenceandID(geneSequenceandID)
      console.log(geneSequences);
      console.log(tokensOfOwner);
      console.log(geneSequenceandID)
    }
    
  }
  getUserNFTs();
},[])

  const [info, setinfo] = useState({})
  if(user != null){
  
  const handleSubmit = async () =>{
    
    var format = /^[a-zA-Z ]+$/;
    var emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if(!format.test($("#fullname").val()) || !emailformat.test($("#email").val())){
      $('#input-error').show();
    }else {
      $('.alert').hide();
    window.$("#profileedit").modal("hide");
    await axios.post('https://blockchainpets.abdulhananjavaid.com/bcpapi/userinfo.php',info)
    console.log(info)
    await getSubmissions()
    }
   
  } 
  const handleChange = (event) =>{
      const info = event.target.name;
      const value = event.target.value;
      setinfo(values => ({
        ...values, [info]:value,
        address:user,
      }))
  }
  $('.close').click(function() {
    $('.alert').hide();
 })
  return (

    <section className="profile-section">
       
      <div className="container">
        <div className="row align-items-center justify-content-center  bcp-profile-info">
          {/* <div className="col-md-4">
            <img className="profile-img" src="images/profileimg.jpg" alt="" />
          </div> */}
          <div className="col-md-8 text-center">
            <p className="psp1" id='user-name'>User Name</p>
            <p className="psp2 mb-2 mt-4">Address Info</p>
            <a
              className="psp3"
              target="_blank"
              href={"https://goerli.etherscan.io/address/" + user}
            >
              {user} <i className="fa-solid fa-up-right-from-square"></i>
            </a>
            <p className="psp2 mt-4 mb-2">Email</p>
            <p className="psp3" id='user-email'>example@gmail.com</p>
            <button
              type="button"
              data-toggle="modal"
              data-target="#profileedit"
              className="btn btn-primary btn-lg"
            >
             Edit Profile
            </button>
            <div
              className="modal fade"
              id="profileedit"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered text-left" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="profileedit">
                     Edit Information
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                <form id="userinfo">
                    
                  <div className="modal-body">
                  <div id='input-error' className = "alert alert-danger alert-dismissable mt-3">
         <button type = "button" className = "close" aria-hidden = "true">
            ×
         </button>
         Name Must Not Have Symbols And Email Must Be In Correct Format
      </div>
                    <div className="form-row">

                      <div className="form-group col-md-12">
                        <label htmlFor="fullname">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullname"
                          name='fullname'
                          placeholder="Full Name"
                          onChange={handleChange}
                          required
                        />
                        
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          name='email'
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {/* <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="picture">Profile Picture</label> <br />
                        <input
                          type="file"
                          className="d-block"
                          id="picture"
                          name='picture'
                          onChange={handleChange}
                          
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" onClick={handleSubmit} className="btn btn-primary">
                      Update Profile
                    </button>
                    
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-owned-nfts">
        <p className="snft-p1 mt-5 text-center">Currently Owned Blockchain Pets NFTs</p>
            <div className="row pt-0" id='owned-dogs-container'>
            {
            geneSequenceandID.map( dog => (
          (<div className="col-md-6 col-lg-4 mt-5" key={dog}>
          <Dogsale srcurl='images/dog3.png' geneSequenceandID={dog.split('-')[0]} generation={dog.split('-')[1]} />
          </div>)
          ))
        
          }
            </div>
        </div>
      </div>
    </section>
  );}else{
    return(<p className='psp1 my-5 py-5'> Connect Wallet To Access Profile Page</p>);
   
  }
}
export default Profile;
