import RegisteredUsers from './RegisteredUsers'
import ContactSubmissions from './ContactSubmissions'
import EmailsSubmitted from './EmailsSubmitted'
import AllNFTs from './AllNFTs'

function AdminPanel() {
	
  return (
    <section className="users-list">
      <p className="text-center bcp-title pt-5 pb-4">Admin Panel</p>
      <div className="container container-admin-lg" id="exTab1">
      <ul  className="nav nav-pills">
			<li >
        <a  href="#pane-1a" className="active" data-toggle="tab">Registered Users</a>
			</li>
			<li><a href="#pane-2a" data-toggle="tab">User Queries</a>
			</li>
			<li><a href="#pane-3a" data-toggle="tab">Emails For Updates</a>
			</li>
			<li><a href="#pane-4a" data-toggle="tab">All NFT Details</a>
			</li>
		</ul>

			<div className="tab-content clearfix">
			  <div className="tab-pane active p-3" id="pane-1a">
			  <RegisteredUsers/>
         
				</div>
				<div className="tab-pane  p-3" id="pane-2a">
          <ContactSubmissions/>
				</div>
        <div className="tab-pane p-3" id="pane-3a">
           <EmailsSubmitted/>
				</div>
				<div className="tab-pane p-4" id="pane-4a">
			<AllNFTs/>
				</div>
         
			</div>
      </div>
    </section>
  );
}
export default AdminPanel;
