import Showcase from './Showcase';
import Header from './Header'
import Instructions from './Instructions';
import { useEffect, useLayoutEffect, useState } from 'react';
import {imgs} from './Imgs'
import $ from 'jquery';
window.onload = function(){
    $(".loading-page").addClass("d-none")
}
function Home(){
  
    const [isLoading,setIsLoading] = useState(true);
    const loadImages = async (srcImgs) => {
        const promises = await srcImgs.map((src)=>{
            return new Promise(function(res,rej){
                const img = new Image();
                img.src = src;
                img.onload = res();
                img.onerror = rej();
            })
        })
        await Promise.all(promises).then(ev=>{
           setIsLoading(false)
        });
        
    }

    useEffect(()=>{
        if(document.readyState === "complete"){
            $(".loading-page").addClass("d-none")
        }
        loadImages(imgs);
    },[])
  
    return(
        <div>

        <div className='loading-page'>
        <img src="images/logoicon.png" className='rotate' alt="" />
    </div>

        <Header/>
    <Showcase/>
    <Instructions/>
   
    </div>
    )
}
export default Home;