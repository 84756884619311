import {Link} from "react-router-dom"
import $ from 'jquery';
import {user,instance,web3,contractAddress} from '../App';
import { collars,legsfeet,eyes,colors,ears,mouths,shades, backs } from "./Createnft";
import {generatetail, checkEyelashes} from "./Dogsale"

function DogForSale(props){
  var geneSequenceandID = props.geneSequenceandID
  var price = props.price;
  var generation = props.generation;
  var geneSequence
  var id
    geneSequence = props.geneSequenceandID.slice(0,11);
    id = props.geneSequenceandID.slice(11,geneSequenceandID.length);
    if(price != undefined){
        return (
            <div>
        <Link to={{pathname: `/Nftdetails`, query: {geneSequenceandID,price,generation}}} className="bcp-img-box-container"><div className="blockchainpets-img-box mt-xl-0 mt-3">
        
        <div className="scdb-container">
        <div className={"show-case-design show-case-design-box position-relative "+geneSequence}>
              <img src={"images/dogparts/d-collar/"+ collars[geneSequence.slice(9,10)] + ".png"} alt="" id="d-collar"/>
              <img src={"images/dogparts/d-head/" + ears[geneSequence.slice(3,4)] + colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-head"/>
              <img src={"images/dogparts/d-eyes/" + eyes[geneSequence.slice(1,2)] + colors[geneSequence.slice(2,3)]  + ".png"} alt="" id="d-eyes" />
              <img src={"images/dogparts/d-legsfeet/" + legsfeet[geneSequence.slice(8,9)] + colors[geneSequence.slice(5,6)] + ".png"} alt="" id="d-legsfeet" />
              <img src={"images/dogparts/d-back/" + backs[geneSequence.slice(7,8)]+ colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-back" />
              <img src={"images/dogparts/d-mouth/" + mouths[geneSequence.slice(6,7)] + colors[geneSequence.slice(5,6)] +".png"} alt="" id="d-mouth"/>
              <img src={"images/dogparts/d-shades/" + shades[geneSequence.slice(10,11)] + ".png"} alt="" id="d-shades"/>
              <img src={generatetail(geneSequence) + colors[geneSequence.slice(4,5)] +".png"} alt="" id="mf-d-tail" />
              <img src={"images/dogparts/f-d-eyelashes/f-d-eyelashes.png"} alt="" id="d-eyelashes" className={checkEyelashes(geneSequence)}/>
        </div>
        </div>
        <div className="row px-3 py-3 justify-content-between align-items-center ">
          <div className="col-3">
            <div className="row align-items-center">
              <div className="col-6">
                <img src="images/logoicon.png" className="dog-img" alt="" />
                </div>
                <div className=" col-6">
                <span className="avi-p1 pl-2">#{id}</span>
               
              </div>
            </div>
          </div>
          <div className="col-5">
          <img src="images/eth.png" className="dog-img" alt="" />
          <span className="avi-p1 text-primary ml-2">{props.price} ETH</span>
          </div>
          <div className="col-3 text-right pr-1">
          <span className="gen-i m-0" to="/#">Gen {props.generation}</span>
          </div>
        </div>
      </div></Link></div>
        )
    }else{
        return (
            <div>
        <Link to={{pathname: `/Nftdetails`, query: {geneSequenceandID,generation}}} className="bcp-img-box-container"><div className="blockchainpets-img-box mt-xl-0 mt-3">
        
        <div className="scdb-container">
        <div className={"show-case-design show-case-design-box position-relative "+geneSequence}>
              <img src={"images/dogparts/d-collar/"+ collars[geneSequence.slice(9,10)] + ".png"} alt="" id="d-collar"/>
              <img src={"images/dogparts/d-head/" + ears[geneSequence.slice(3,4)] + colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-head"/>
              <img src={"images/dogparts/d-eyes/" + eyes[geneSequence.slice(1,2)] + colors[geneSequence.slice(2,3)]  + ".png"} alt="" id="d-eyes" />
              <img src={"images/dogparts/d-legsfeet/" + legsfeet[geneSequence.slice(8,9)] + colors[geneSequence.slice(5,6)] + ".png"} alt="" id="d-legsfeet" />
              <img src={"images/dogparts/d-back/" + backs[geneSequence.slice(7,8)]+ colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-back" />
              <img src={"images/dogparts/d-mouth/" + mouths[geneSequence.slice(6,7)] + colors[geneSequence.slice(5,6)] +".png"} alt="" id="d-mouth"/>
              <img src={"images/dogparts/d-shades/" + shades[geneSequence.slice(10,11)] + ".png"} alt="" id="d-shades"/>
              <img src={generatetail(geneSequence) + colors[geneSequence.slice(4,5)] +".png"} alt="" id="mf-d-tail" />
              <img src={"images/dogparts/f-d-eyelashes/f-d-eyelashes.png"} alt="" id="d-eyelashes" className={checkEyelashes(geneSequence)}/>
        </div>
        </div>
        <div className="row px-3 py-3 justify-content-between align-items-center ">
          <div className="col-3">
            <div className="row align-items-center">
              <div className="col-6">
                <img src="images/logoicon.png" className="dog-img" alt="" />
                </div>
                <div className=" col-6">
                <span className="avi-p1 pl-2">#{id}</span>
               
              </div>
            </div>
          </div>
          <div className="col-5">
          <img src="images/eth.png" className="dog-img" alt="" />
          <span className="avi-p1 text-primary ml-2">{props.price} ETH</span>
          </div>
          <div className="col-3 text-right pr-1">
          <span className="gen-i m-0" to="/#">Gen {props.generation}</span>
          </div>
        </div>
      </div></Link></div>
        )
    }
  
}
export default DogForSale