import $ from 'jquery';

import {user,instance,web3,contractAddress} from '../App';


function changeEyeShape(){
    $("#d-eyes").prop("src","images/dogparts/d-eyes/" + eyes[ $("#EyeShape").val()] + colors[$("#EyeColor").val()] + ".png")
    updateGeneSequence()
}
function changeHeadShape(){
    $("#d-head").prop("src","images/dogparts/d-head/" + ears[ $("#HeadShape").val()] + colors[$("#UpperBodyColor").val()] + ".png")
    updateGeneSequence()
}
function changeUpperBodyColor(){
    $("#d-head").prop("src","images/dogparts/d-head/" + ears[ $("#HeadShape").val()] + colors[$("#UpperBodyColor").val()] + ".png")
    $("#d-back").prop("src","images/dogparts/d-back/" + backs[ $("#Back").val()] + colors[$("#UpperBodyColor").val()] + ".png")
    var input = $("#gender-f");
    if(input.prop("checked")){
        $("#mf-d-tail").prop("src","images/dogparts/mf-d-tail/f-d-tail" + colors[$("#UpperBodyColor").val()]  + ".png")

    } else{
        $("#mf-d-tail").prop("src","images/dogparts/mf-d-tail/m-d-tail" + colors[$("#UpperBodyColor").val()]  + ".png")
    }
    updateGeneSequence()
}
function changeLowerBodyColor(){
    $("#d-mouth").prop("src","images/dogparts/d-mouth/" + mouths[ $("#Mouth").val()] + colors[$("#LowerBodyColor").val()] + ".png")
    $("#d-legsfeet").prop("src","images/dogparts/d-legsfeet/" + legsfeet[ $("#LegsAndFeet").val()] + colors[$("#LowerBodyColor").val()] + ".png")
    updateGeneSequence()
}
function changeLegsFeet(){
    $("#d-legsfeet").prop("src","images/dogparts/d-legsfeet/" + legsfeet[ $("#LegsAndFeet").val()] + colors[$("#LowerBodyColor").val()] + ".png")
    updateGeneSequence()
}
function changeMouth(){
    $("#d-mouth").prop("src","images/dogparts/d-mouth/" + mouths[ $("#Mouth").val()] + colors[$("#LowerBodyColor").val()] + ".png")
    updateGeneSequence()
}
function changeEyeColor(){
    $("#d-eyes").prop("src","images/dogparts/d-eyes/" + eyes[ $("#EyeShape").val()] + colors[$("#EyeColor").val()] + ".png")
    updateGeneSequence()
}
function changeBack(){
    $("#d-back").prop("src","images/dogparts/d-back/" + backs[ $("#Back").val()] + colors[$("#UpperBodyColor").val()] + ".png")
    updateGeneSequence()
}
function changeCollar(){
    $("#d-collar").prop("src","images/dogparts/d-collar/" + collars[ $("#Collar").val()]  + ".png")
    updateGeneSequence()
}
function changeShades(){
    $("#d-shades").prop("src","images/dogparts/d-shades/" + shades[ $("#Shades").val()]  + ".png")
    updateGeneSequence()
}
// function changeTail(){
//     var input = $("#gender-f");
//     if(input.prop("checked")){
//         $("#mf-d-tail").prop("src","images/dogparts/mf-d-tail/f-d-tail" + colors[$("#UpperBodyColor").val()]  + ".png")

//     } else{
//         $("#mf-d-tail").prop("src","images/dogparts/mf-d-tail/m-d-tail" + colors[$("#UpperBodyColor").val()]  + ".png")
//     }
    
// }
// function changeBackground(){
//     $("#d-background").prop("src","images/dogparts/d-backgrounds/" + backgrounds[$("#Background").val()] + ".png");
// }

function genderChange(){
    var eyelash = $("#d-eyelashes");
    var input = $("#gender-f");
    if(input.prop("checked")){
        eyelash.removeClass("d-none");
        changeUpperBodyColor();

    } else{
        eyelash.addClass("d-none");
        changeUpperBodyColor();
    }
    updateGeneSequence()
}

// var defaultGene = "M0000000000";
export function loadUsingGeneSequence(geneSequence){
    $("#EyeShape").val(geneSequence.charAt(1)) //12
    $("#EyeColor").val(geneSequence.charAt(2)) //23
    $("#HeadShape").val(geneSequence.charAt(3)) //34
    $("#UpperBodyColor").val(geneSequence.charAt(4)) //45
    $("#LowerBodyColor").val(geneSequence.charAt(5)) //56
    $("#Mouth").val(geneSequence.charAt(6)) //67
    $("#Back").val(geneSequence.charAt(7)) //78
    $("#LegsAndFeet").val(geneSequence.charAt(8)) //89
    $("#Collar").val(geneSequence.charAt(9)) //910
    $("#Shades").val(geneSequence.charAt(9))//1011
    updateAllParts()
}

export var collars = [
    "d-collar (1)",
    "d-collar (2)",
    "d-collar (3)",
    "d-collar (4)",
    "d-collar (5)",
    "d-collar (6)",
    "d-collar (7)",
    "d-collar (8)",
    "d-collar (9)",
    "d-collar (10)",
]
export var shades = [
    "d-shade (1)",
    "d-shade (2)",
    "d-shade (3)",
    "d-shade (4)",
    "d-shade (5)",
    "d-shade (6)",
    "d-shade (7)",
    "d-shade (8)",
    "d-shade (9)",
    "d-shade (10)",
]
export var eyes = [
    "d-a-eyes",
    "d-b-eyes",
    "d-c-eyes",
    "d-d-eyes",
    "d-e-eyes",
    "d-f-eyes",
    "d-g-eyes",
    "d-h-eyes",
    "d-i-eyes",
    "d-j-eyes",
    
];
export var mouths = [
    "d-a-mouth",
    "d-b-mouth",
    "d-c-mouth",
    "d-d-mouth",
    "d-e-mouth",
    "d-f-mouth",
    "d-g-mouth",
    "d-h-mouth",
    "d-i-mouth",
    "d-j-mouth",
]
export var legsfeet = [
    "d-a-legsfeet",
    "d-b-legsfeet",
    "d-c-legsfeet",
    "d-d-legsfeet",
    "d-e-legsfeet",
    "d-f-legsfeet",
    "d-g-legsfeet",
    "d-h-legsfeet",
    "d-i-legsfeet",
    "d-j-legsfeet",
]
// var tails = [
//     "d-a-tail",
//     "d-b-tail",
//     "d-c-tail",
//     "d-mf-d-tail",
//     "d-e-tail",
// ]
export var backs = [
    "d-a-back",
    "d-b-back",
    "d-c-back",
    "d-d-back",
    "d-e-back",
    "d-f-back",
    "d-g-back",
    "d-h-back",
    "d-i-back",
    "d-j-back",
];
export var ears = [
    "d-a-head",
    "d-b-head",
    "d-c-head",
    "d-d-head",
    "d-e-head",
    "d-f-head",
    "d-g-head",
    "d-h-head",
    "d-i-head",
    "d-j-head",
];
export var colors = [
    " (1)",
    " (2)",
    " (3)",
    " (4)",
    " (5)",
    " (6)",
    " (7)",
    " (8)",
    " (9)",
    " (10)",
]

// var backgrounds = [
//     "d-background (1)",
//     "d-background (2)",
//     "d-background (3)",
//     "d-background (4)",
//     "d-background (5)",
//     "d-background (6)",
//     "d-background (7)",
// ]

function generateRandomDesign(){
    var randomEyeshape = Math.random() * 9;
    var randomEyeColor = Math.random() * 9;
    var randomHeadShape = Math.random() * 9;
    var randomUpperBodyColor = Math.random() * 9;
    var randomLowerBodyColor = Math.random() * 9;
    var randomMouth = Math.random() * 9;
    var randomBack = Math.random() * 9;
    var randomLegsFeet = Math.random() * 9;
    var randomCollar = Math.random() * 9;
    var randomShades = Math.random() * 9;
    // var randomTail = Math.random() * 9;
    // var randomBackground = Math.random() * 7;


    $("#EyeShape").val(randomEyeshape)
    $("#EyeColor").val(randomEyeColor)
    $("#HeadShape").val(randomHeadShape)
    $("#UpperBodyColor").val(randomUpperBodyColor)
    $("#LowerBodyColor").val(randomLowerBodyColor)
    $("#Mouth").val(randomMouth)
    $("#Back").val(randomBack)
    $("#LegsAndFeet").val(randomLegsFeet)
    $("#Collar").val(randomCollar)
    $("#Shades").val(randomShades)
    // $("#Tail").val(randomTail)
    // $("#Background").val(randomBackground)

    updateAllParts()
   
}
function updateAllParts(){
    changeBack()
    changeCollar()
    changeShades()
    changeHeadShape()
    changeEyeColor()
    changeEyeShape()
    changeLegsFeet()
    changeLowerBodyColor()
    changeMouth()
    // changeTail()
    changeUpperBodyColor()
    updateGeneSequence()
    // changeBackground()
}
function updateGeneSequence(){
    $("#gene-sequence").html(getGeneSequence())
}
function getGeneSequence(){
    geneSequence = $("#EyeShape").val() +
    $("#EyeColor").val() +
    $("#HeadShape").val() +
    $("#UpperBodyColor").val() +
    $("#LowerBodyColor").val() +
    $("#Mouth").val() +
    $("#Back").val() +
    $("#LegsAndFeet").val() +
    $("#Collar").val() +
    $("#Shades").val()

    var input = $("#gender-f");
    if(input.prop("checked")){
        geneSequence = "2" + geneSequence;

    } else{
        geneSequence = "1" + geneSequence;
    }

    return geneSequence;
}

function mintDog(){
    instance.events.Birth().on('data',function(event){
        var dogId = event.returnValues.DogId;
        var genes = event.returnValues.geneSequence;
        window.$("#DogBirth").modal('show')
        $('#dogBirthText').text("Dog created with ID: " + dogId + " and Gene Sequence: " + genes+ " ");
    })
    .on('error',console.error);
    instance.methods.createDogGen0(geneSequence).send({},function(error,txHash){
        if(error){
            console.log(error);
        } else{
            console.log(txHash)
        }
    })
}
var geneSequence = "10000000000"



function Createnft(){
    geneSequence = "10000000000"
    return(
        <section className="create-nfts">
           
            <div className="container">
            <p className="text-center bcp-title py-4">Design A Dog NFT</p>
                <div className="row">
                    <div className="col-lg-7 dog-container-col">
                        <div className="show-case-design position-relative">
                            <img src="images/dogparts/d-collar/d-collar (1).png" alt="" id="d-collar"/>
                            <img src="images/dogparts/d-head/d-a-head (1).png" alt="" id="d-head"/>
                            <img src="images/dogparts/d-eyes/d-a-eyes (1).png" alt="" id="d-eyes" />
                            <img src="images/dogparts/d-legsfeet/d-a-legsfeet (1).png" alt="" id="d-legsfeet" />
                            <img src="images/dogparts/d-back/d-a-back (1).png" alt="" id="d-back" />
                            <img src="images/dogparts/d-mouth/d-a-mouth (1).png" alt="" id="d-mouth"/>
                            <img src="images/dogparts/d-shades/d-shade (1).png" alt="" id="d-shades"/>
                            <img src="images/dogparts/mf-d-tail/m-d-tail (1).png" alt="" id="mf-d-tail" />
                            <img src="images/dogparts/f-d-eyelashes/f-d-eyelashes.png" alt="" id="d-eyelashes" className='d-none' />

                            {/* <img src="images/SVG/Collar 1.svg" alt="" id="d-collar"/>
                            <img src="images/SVG/Ears 1.svg" alt="" id="d-head"/>
                            <img src="images/SVG/Eyes 1_14.svg" alt="" id="d-eyes" />
                            <img src="images/SVG/Legs and Feet.svg" alt="" id="d-legsfeet" />
                            <img src="images/SVG/Main bod 1.svg" alt="" id="d-back" />
                            <img src="images/SVG/Mouth 1_3.svg" alt="" id="d-mouth"/>
                            <img src="images/SVG/Tail 1.svg" alt="" id="mf-d-tail" /> */}
                            {/* <img src="images/SVG/f-d-eyelashes.svg" alt="" id="d-eyelashes" className='d-none' /> */}
                            <div id="d-background"> </div>
                            {/* <img src="images/dogparts/d-a-glasses.png" alt="" /> */}
                        </div>
                        <div className='genesequence-container'>
                        <span className='pt-3 d-inline-block pr-2'>Gene Sequence: </span><span className='pt-3 d-inline-block' id='gene-sequence'>{geneSequence}</span>
                        </div>
                        
                    </div>
                    <div className="col-lg-5">
                    <button onClick={generateRandomDesign} className='btn btn-primary btn-block grd-1'>Generate Random Design</button>
                        <label htmlFor="EyeShape">Eye Shape</label>
                        <input onInput={changeEyeShape} type="range" className="custom-range" id="EyeShape" min="0" max="9" defaultValue="0"/>
                        <label htmlFor="EyeColor">Eye Color</label>
                        <input onInput={changeEyeColor} type="range" className="custom-range" id="EyeColor" min="0" max="9" defaultValue="0"/>
                        <label htmlFor="HeadShape">Head</label>
                        <input onInput={changeHeadShape} type="range" className="custom-range" id="HeadShape" min="0" max="9" defaultValue="0"/>
                        <label htmlFor="UpperBodyColor">Upper Body Color</label>
                        <input onInput={changeUpperBodyColor} type="range" className="custom-range" id="UpperBodyColor" min="0" max="9" defaultValue="0"/>
                        <label htmlFor="LowerBodyColor">Lower Body Color</label>
                        <input onInput={changeLowerBodyColor} type="range" className="custom-range" id="LowerBodyColor" min="0" max="9" defaultValue="0"/>
                        <label htmlFor="Mouth">Mouth</label>
                        <input onInput={changeMouth} type="range" className="custom-range" id="Mouth" min="0" max="9" defaultValue="0"/>
                        <label htmlFor="Back">Back</label>
                        <input onInput={changeBack} type="range" className="custom-range" min="0" max="9" defaultValue="0" id="Back"/>
                        <label htmlFor="LegsAndFeet">Legs And Feet</label>
                        <input onInput={changeLegsFeet} type="range" className="custom-range" id="LegsAndFeet" min="0" max="9" defaultValue="0" />
                        <label htmlFor="Collar">Collar</label>
                        <input onInput={changeCollar} type="range" className="custom-range" id="Collar" min="0" max="9" defaultValue="0"/>
                        <label htmlFor="Shades">Shades</label>
                        <input onInput={changeShades} type="range" className="custom-range" id="Shades" min="0" max="9" defaultValue="0"/>
                        {/* <label htmlFor="Tail">Tail</label>
                        <input onInput={changeTail} type="range" className="custom-range" id="Tail" min="0" max="4" defaultValue="0"/> */}
                        {/* <label htmlFor="Background">Background</label>
                        <input onInput={changeBackground} type="range" className="custom-range" id="Background" min="0" max="6" defaultValue="0"/> */}
                        {/* <label htmlFor="PatternColor">Pattern Color</label>
                        <input type="range" className="custom-range" id="PatternColor"/> */}
                        <label htmlFor="Gender" className="pr-3 pt-2">Gender: </label>
                        
                        <div className="form-check form-check-inline">
                        <input onChange={genderChange} className="form-check-input" type="radio" name="gender" id="gender-m" value="male" defaultChecked/>
                        <label className="form-check-label" htmlFor="gender-m">Male</label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input onChange={genderChange} className="form-check-input" type="radio" name="gender" id="gender-f" value="female" />
                        <label className="form-check-label" htmlFor="gender-f">Female</label>
                        </div>
                        <button onClick={generateRandomDesign} className='btn btn-primary btn-block grd-2'>Generate Random Design</button>
                    </div>
                    
                </div>
                <button className="mint-btn btn btn-block mt-3 btn-primary" onClick={mintDog}>MINT NFT</button>
                
            </div>
           
            <div className="modal" tabIndex="-1" role="dialog" id='DogBirth'>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Dog Minted Successfully</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p id='dogBirthText'></p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
            </div>
        </section>
    )
}
  


// $("#EyeShape").on("input", function(){
   
// })
export default Createnft