import Dogsale from "./Dogsale";
import Dogsell from "./Dogsell";
import DogPriced from "./DogPriced";
import $ from 'jquery';
import { useState,useEffect } from 'react'
import {user,instance,web3,contractAddress} from '../App';

function Sell() {
var [tokensOfOwner, setTokensOfOwner] = useState([]); 
var [geneSequences, setGeneSequences] = useState([]); 
var [geneSequenceandID, setGeneSequenceandID] = useState([]); 
var [geneSequencesSelling, setGeneSequencesSelling] = useState([]); 
if(instance != null){
  instance.events.MarketTransaction()
  .on('data', (event) => {
    console.log(event);
    var eventName = event.returnValues["TxType"].toString()
    var tokenId = event.returnValues["tokenId"]
    console.log("testing: " + eventName + " and " + tokenId)
    if(eventName == "Create offer"){
      window.$('.modal.fade').modal('hide')
      window.$("#dog-listing").modal('show')
      $(".dog-listing-text").html('Dog Listed For Sale Successfully')
      getUserNFTs()
    }
    if(eventName == "Remove offer"){
      window.$('.modal.fade').modal('hide')
      window.$("#dog-listing").modal('show')
      $(".dog-listing-text").html('Listing Removed')
      getUserNFTs()
    }
  })
}

async function getUserNFTs(){
  
  if(instance !=null){
    tokensOfOwner = await instance.methods.tokensOfOwner(user).call()
    // const totalSupply = await instance.methods.totalSupply().call()
    geneSequences = [];
    geneSequencesSelling = [];
    geneSequenceandID= [];
    for(const token of tokensOfOwner){
      // geneSequences.push(await instance.methods.getDog(token).call())
      var tokenOffer = await instance.methods.getOffer(token).call()
      if(tokenOffer.price != 0){
        console.log(web3.utils.fromWei(tokenOffer.price, 'ether'))
        var sequence = await instance.methods.getDog(token).call()
        sequence = sequence.geneSequence + token + "-" + sequence.generation  + "-" + web3.utils.fromWei(tokenOffer.price, 'ether');
        geneSequencesSelling.push(sequence)
      }else{
        var sequence = await instance.methods.getDog(token).call()
        sequence = sequence.geneSequence + token + "-" + sequence.generation
        geneSequenceandID.push(sequence);
      }
    }
    
    
    // for(var i = 0; i < tokensOfOwner.length; i++){
    //   geneSequenceandID.push(geneSequences[i].geneSequence + tokensOfOwner[i] + "-" + geneSequences[i].generation);
      
    // }

    setTokensOfOwner(tokensOfOwner)
    setGeneSequences(geneSequences)
    setGeneSequencesSelling(geneSequencesSelling)
    setGeneSequenceandID(geneSequenceandID)
    console.log(geneSequences);
    console.log(geneSequencesSelling);
    console.log(tokensOfOwner);
    console.log(geneSequenceandID)
  }
  
}
useEffect(()=>{
  
  getUserNFTs();
},[])

  return (
    <section className="sell-nfts">
      <div className="container">
        <p className="snft-p1">NFTs Available to sell</p>
        <div className="row pt-0">
        {
            geneSequenceandID.map( dog => (
          (<div className="col-md-6 col-lg-4 mt-5" key={dog}>
          <Dogsell srcurl='images/dog3.png' geneSequenceandID={dog.split('-')[0]} generation={dog.split('-')[1]} />
          </div>)
          ))
        
          }
         
        </div>
        <p className="snft-p1">NFTs Already Listed for sale</p>
        <div className="row pt-0">

        {
            geneSequencesSelling.map( dog => (
          (<div className="col-md-6 col-lg-4 mt-5" key={dog}> 
          <DogPriced srcurl='images/dog3.png' geneSequenceandID={dog.split('-')[0]} generation={dog.split('-')[1]} price={dog.split('-')[2]}/>
          </div>)
          ))
        
          }

      
          
        </div>
      </div>
      <div className="modal" tabIndex="-1" role="dialog" id='dog-listing'>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title dog-listing-text">Dog Listed For Sale!</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p id='dog-listing-text' className="dog-listing-text"></p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
            </div>
    </section>
  );
 
}
export default Sell;
