function Instructions(){
    return(
        <section className="bcp-nft-instructions" id="instructions">
        <p className="text-center bcp-title py-4">Create, Mint & Sell Dog NFTs!</p>
        <div className="container">
        <div className="row no-gutters text-center">
            <div className="col-lg-4">
            <img src="images/wallet.png" alt="" className="cs-imgs"/>
            <p className="bcpi-p">
                Set up <a href="https://metamask.io/download/">Metamask</a> and connect it to Blockchain Pets by clicking the connect wallet option in the top right corner of the navigation bar
            </p>
            </div>
            <div className="col-lg-4">
            <img src="images/paint-palette.png" alt="" className="cs-imgs"/>
            <p className="bcpi-p">
             Create an NFT using our NFT design tool. You can create a dog just the way you like and then mint it as an NFT!
            </p>
            </div>
            <div className="col-lg-4">
            <img src="images/price-tag.png" alt="" className="cs-imgs"/>
            <p className="bcpi-p">
            You can now sell your newly created 3D NFTs by listing them on our NFT marketplace with minimal fee only when your NFT is sold!
            </p>
            </div> 
        </div>
        </div>
    </section>
    )
}
export default Instructions;