import Copyrights from "./Copyrights";
import { Link } from "react-router-dom"
import ConnectionModal from "./ConnectionModal";
function Footer(){
    return (  
        <div>
        <footer className="blockchainpets-footer position-relative">
          <div className="overlay-blockchainpets-footer"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 align-self-center">
                <p className="blockchainpets-f-logo">
                  <img src="images/bcp-logo2.svg" alt="" />
                </p>
              </div>
              <div className="col-lg-4">
                <p className="bcp-title1">PAGES</p>
                <div className="d-flex">
                  <div className="blockchainpets-f-links">
                    <Link  to="/createnft">Create NFT</Link> 
                    <Link  to="/marketplace">Marketplace</Link> 
                    <Link  to="/profile">Profile</Link> 
                    <a  href="https://blockchainpets.abdulhananjavaid.com/#instructions">How to use</a> 
                    {/* <Link  to="/#">Terms Of Use</Link>  */}
                    <Link  to="/adminlogin">Admin</Link> 
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <p className="bcp-title1">SOCIAL</p>
                <div className="social-links-container">
                  <a target="_blank" href="https://discord.gg/cYSvJjyshY"  className="social-f-link">
                    <img src="images/discord-circle.png" alt="" />
                  </a> 
                  {/* <a target="_blank" href="/#"  className="social-f-link">
                    <img src="images/telegram-circle.png" alt="" />
                  </a>  */}
                <a target="_blank" href="https://instagram.com/blockchainpets_dogs" className="social-f-link">
                    <img src="images/instagram-circle.png" alt="" />
                  </a> 
                  <a target="_blank" href="https://twitter.com/pets_blockchain"  className="social-f-link">
                    <img src="images/twitter-circle.png" alt="" />
                  </a> 
                
                </div>
                <div className="social-links-container">
                  
                  <a target="_blank" href="https://www.facebook.com/profile.php?id=100088447045969" className="social-f-link">
                    <img src="images/facebook-circle.png" alt="" />
                  </a> 
                  <a target="_blank" href="https://www.youtube.com/channel/UC3MTZqm8tbFla0ZlW20qe9Q" className="social-f-link">
                    <img src="images/youtube.svg" alt="" />
                  </a> 
                 
                </div>
              </div>
            </div>
          </div>
        <ConnectionModal/>
        </footer>
        <Copyrights/>
        </div>
        )
}
export default Footer;