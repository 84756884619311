///GENERATED USING 
    // imgs.push("images/dogparts/f-d-eyelashes/f-d-eyelashes.png")
    // for(var i =0;i<10;i++){
    //     for(var j =0;j<10;j++){
    //         imgs.push("images/dogparts/d-eyes/" + eyes[i] + colors[j] + ".png")
    //         imgs.push("images/dogparts/d-head/" + ears[i] + colors[j] + ".png")
    //         imgs.push("images/dogparts/d-back/" + backs[i] + colors[j] + ".png")
    //         imgs.push("images/dogparts/d-mouth/" + mouths[i] + colors[j] + ".png")
    //         imgs.push("images/dogparts/d-legsfeet/" + legsfeet[i] + colors[j] + ".png")

            
    //     }
    //     imgs.push("images/dogparts/mf-d-tail/f-d-tail" + colors[i]  + ".png")
    //     imgs.push("images/dogparts/mf-d-tail/m-d-tail" + colors[i]  + ".png")
    //     imgs.push("images/dogparts/d-collar/" + collars[i]  + ".png")
    //     imgs.push("images/dogparts/d-shades/" + shades[i]  + ".png")
   
    // }
    // console.log(imgs);
export const imgs=[
    "images/dogparts/f-d-eyelashes/f-d-eyelashes.png",
    "images/dogparts/d-eyes/d-a-eyes (1).png",
    "images/dogparts/d-head/d-a-head (1).png",
    "images/dogparts/d-back/d-a-back (1).png",
    "images/dogparts/d-mouth/d-a-mouth (1).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (1).png",
    "images/dogparts/d-eyes/d-a-eyes (2).png",
    "images/dogparts/d-head/d-a-head (2).png",
    "images/dogparts/d-back/d-a-back (2).png",
    "images/dogparts/d-mouth/d-a-mouth (2).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (2).png",
    "images/dogparts/d-eyes/d-a-eyes (3).png",
    "images/dogparts/d-head/d-a-head (3).png",
    "images/dogparts/d-back/d-a-back (3).png",
    "images/dogparts/d-mouth/d-a-mouth (3).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (3).png",
    "images/dogparts/d-eyes/d-a-eyes (4).png",
    "images/dogparts/d-head/d-a-head (4).png",
    "images/dogparts/d-back/d-a-back (4).png",
    "images/dogparts/d-mouth/d-a-mouth (4).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (4).png",
    "images/dogparts/d-eyes/d-a-eyes (5).png",
    "images/dogparts/d-head/d-a-head (5).png",
    "images/dogparts/d-back/d-a-back (5).png",
    "images/dogparts/d-mouth/d-a-mouth (5).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (5).png",
    "images/dogparts/d-eyes/d-a-eyes (6).png",
    "images/dogparts/d-head/d-a-head (6).png",
    "images/dogparts/d-back/d-a-back (6).png",
    "images/dogparts/d-mouth/d-a-mouth (6).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (6).png",
    "images/dogparts/d-eyes/d-a-eyes (7).png",
    "images/dogparts/d-head/d-a-head (7).png",
    "images/dogparts/d-back/d-a-back (7).png",
    "images/dogparts/d-mouth/d-a-mouth (7).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (7).png",
    "images/dogparts/d-eyes/d-a-eyes (8).png",
    "images/dogparts/d-head/d-a-head (8).png",
    "images/dogparts/d-back/d-a-back (8).png",
    "images/dogparts/d-mouth/d-a-mouth (8).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (8).png",
    "images/dogparts/d-eyes/d-a-eyes (9).png",
    "images/dogparts/d-head/d-a-head (9).png",
    "images/dogparts/d-back/d-a-back (9).png",
    "images/dogparts/d-mouth/d-a-mouth (9).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (9).png",
    "images/dogparts/d-eyes/d-a-eyes (10).png",
    "images/dogparts/d-head/d-a-head (10).png",
    "images/dogparts/d-back/d-a-back (10).png",
    "images/dogparts/d-mouth/d-a-mouth (10).png",
    "images/dogparts/d-legsfeet/d-a-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (1).png",
    "images/dogparts/mf-d-tail/m-d-tail (1).png",
    "images/dogparts/d-collar/d-collar (1).png",
    "images/dogparts/d-shades/d-shade (1).png",
    "images/dogparts/d-eyes/d-b-eyes (1).png",
    "images/dogparts/d-head/d-b-head (1).png",
    "images/dogparts/d-back/d-b-back (1).png",
    "images/dogparts/d-mouth/d-b-mouth (1).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (1).png",
    "images/dogparts/d-eyes/d-b-eyes (2).png",
    "images/dogparts/d-head/d-b-head (2).png",
    "images/dogparts/d-back/d-b-back (2).png",
    "images/dogparts/d-mouth/d-b-mouth (2).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (2).png",
    "images/dogparts/d-eyes/d-b-eyes (3).png",
    "images/dogparts/d-head/d-b-head (3).png",
    "images/dogparts/d-back/d-b-back (3).png",
    "images/dogparts/d-mouth/d-b-mouth (3).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (3).png",
    "images/dogparts/d-eyes/d-b-eyes (4).png",
    "images/dogparts/d-head/d-b-head (4).png",
    "images/dogparts/d-back/d-b-back (4).png",
    "images/dogparts/d-mouth/d-b-mouth (4).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (4).png",
    "images/dogparts/d-eyes/d-b-eyes (5).png",
    "images/dogparts/d-head/d-b-head (5).png",
    "images/dogparts/d-back/d-b-back (5).png",
    "images/dogparts/d-mouth/d-b-mouth (5).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (5).png",
    "images/dogparts/d-eyes/d-b-eyes (6).png",
    "images/dogparts/d-head/d-b-head (6).png",
    "images/dogparts/d-back/d-b-back (6).png",
    "images/dogparts/d-mouth/d-b-mouth (6).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (6).png",
    "images/dogparts/d-eyes/d-b-eyes (7).png",
    "images/dogparts/d-head/d-b-head (7).png",
    "images/dogparts/d-back/d-b-back (7).png",
    "images/dogparts/d-mouth/d-b-mouth (7).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (7).png",
    "images/dogparts/d-eyes/d-b-eyes (8).png",
    "images/dogparts/d-head/d-b-head (8).png",
    "images/dogparts/d-back/d-b-back (8).png",
    "images/dogparts/d-mouth/d-b-mouth (8).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (8).png",
    "images/dogparts/d-eyes/d-b-eyes (9).png",
    "images/dogparts/d-head/d-b-head (9).png",
    "images/dogparts/d-back/d-b-back (9).png",
    "images/dogparts/d-mouth/d-b-mouth (9).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (9).png",
    "images/dogparts/d-eyes/d-b-eyes (10).png",
    "images/dogparts/d-head/d-b-head (10).png",
    "images/dogparts/d-back/d-b-back (10).png",
    "images/dogparts/d-mouth/d-b-mouth (10).png",
    "images/dogparts/d-legsfeet/d-b-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (2).png",
    "images/dogparts/mf-d-tail/m-d-tail (2).png",
    "images/dogparts/d-collar/d-collar (2).png",
    "images/dogparts/d-shades/d-shade (2).png",
    "images/dogparts/d-eyes/d-c-eyes (1).png",
    "images/dogparts/d-head/d-c-head (1).png",
    "images/dogparts/d-back/d-c-back (1).png",
    "images/dogparts/d-mouth/d-c-mouth (1).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (1).png",
    "images/dogparts/d-eyes/d-c-eyes (2).png",
    "images/dogparts/d-head/d-c-head (2).png",
    "images/dogparts/d-back/d-c-back (2).png",
    "images/dogparts/d-mouth/d-c-mouth (2).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (2).png",
    "images/dogparts/d-eyes/d-c-eyes (3).png",
    "images/dogparts/d-head/d-c-head (3).png",
    "images/dogparts/d-back/d-c-back (3).png",
    "images/dogparts/d-mouth/d-c-mouth (3).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (3).png",
    "images/dogparts/d-eyes/d-c-eyes (4).png",
    "images/dogparts/d-head/d-c-head (4).png",
    "images/dogparts/d-back/d-c-back (4).png",
    "images/dogparts/d-mouth/d-c-mouth (4).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (4).png",
    "images/dogparts/d-eyes/d-c-eyes (5).png",
    "images/dogparts/d-head/d-c-head (5).png",
    "images/dogparts/d-back/d-c-back (5).png",
    "images/dogparts/d-mouth/d-c-mouth (5).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (5).png",
    "images/dogparts/d-eyes/d-c-eyes (6).png",
    "images/dogparts/d-head/d-c-head (6).png",
    "images/dogparts/d-back/d-c-back (6).png",
    "images/dogparts/d-mouth/d-c-mouth (6).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (6).png",
    "images/dogparts/d-eyes/d-c-eyes (7).png",
    "images/dogparts/d-head/d-c-head (7).png",
    "images/dogparts/d-back/d-c-back (7).png",
    "images/dogparts/d-mouth/d-c-mouth (7).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (7).png",
    "images/dogparts/d-eyes/d-c-eyes (8).png",
    "images/dogparts/d-head/d-c-head (8).png",
    "images/dogparts/d-back/d-c-back (8).png",
    "images/dogparts/d-mouth/d-c-mouth (8).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (8).png",
    "images/dogparts/d-eyes/d-c-eyes (9).png",
    "images/dogparts/d-head/d-c-head (9).png",
    "images/dogparts/d-back/d-c-back (9).png",
    "images/dogparts/d-mouth/d-c-mouth (9).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (9).png",
    "images/dogparts/d-eyes/d-c-eyes (10).png",
    "images/dogparts/d-head/d-c-head (10).png",
    "images/dogparts/d-back/d-c-back (10).png",
    "images/dogparts/d-mouth/d-c-mouth (10).png",
    "images/dogparts/d-legsfeet/d-c-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (3).png",
    "images/dogparts/mf-d-tail/m-d-tail (3).png",
    "images/dogparts/d-collar/d-collar (3).png",
    "images/dogparts/d-shades/d-shade (3).png",
    "images/dogparts/d-eyes/d-d-eyes (1).png",
    "images/dogparts/d-head/d-d-head (1).png",
    "images/dogparts/d-back/d-d-back (1).png",
    "images/dogparts/d-mouth/d-d-mouth (1).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (1).png",
    "images/dogparts/d-eyes/d-d-eyes (2).png",
    "images/dogparts/d-head/d-d-head (2).png",
    "images/dogparts/d-back/d-d-back (2).png",
    "images/dogparts/d-mouth/d-d-mouth (2).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (2).png",
    "images/dogparts/d-eyes/d-d-eyes (3).png",
    "images/dogparts/d-head/d-d-head (3).png",
    "images/dogparts/d-back/d-d-back (3).png",
    "images/dogparts/d-mouth/d-d-mouth (3).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (3).png",
    "images/dogparts/d-eyes/d-d-eyes (4).png",
    "images/dogparts/d-head/d-d-head (4).png",
    "images/dogparts/d-back/d-d-back (4).png",
    "images/dogparts/d-mouth/d-d-mouth (4).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (4).png",
    "images/dogparts/d-eyes/d-d-eyes (5).png",
    "images/dogparts/d-head/d-d-head (5).png",
    "images/dogparts/d-back/d-d-back (5).png",
    "images/dogparts/d-mouth/d-d-mouth (5).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (5).png",
    "images/dogparts/d-eyes/d-d-eyes (6).png",
    "images/dogparts/d-head/d-d-head (6).png",
    "images/dogparts/d-back/d-d-back (6).png",
    "images/dogparts/d-mouth/d-d-mouth (6).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (6).png",
    "images/dogparts/d-eyes/d-d-eyes (7).png",
    "images/dogparts/d-head/d-d-head (7).png",
    "images/dogparts/d-back/d-d-back (7).png",
    "images/dogparts/d-mouth/d-d-mouth (7).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (7).png",
    "images/dogparts/d-eyes/d-d-eyes (8).png",
    "images/dogparts/d-head/d-d-head (8).png",
    "images/dogparts/d-back/d-d-back (8).png",
    "images/dogparts/d-mouth/d-d-mouth (8).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (8).png",
    "images/dogparts/d-eyes/d-d-eyes (9).png",
    "images/dogparts/d-head/d-d-head (9).png",
    "images/dogparts/d-back/d-d-back (9).png",
    "images/dogparts/d-mouth/d-d-mouth (9).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (9).png",
    "images/dogparts/d-eyes/d-d-eyes (10).png",
    "images/dogparts/d-head/d-d-head (10).png",
    "images/dogparts/d-back/d-d-back (10).png",
    "images/dogparts/d-mouth/d-d-mouth (10).png",
    "images/dogparts/d-legsfeet/d-d-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (4).png",
    "images/dogparts/mf-d-tail/m-d-tail (4).png",
    "images/dogparts/d-collar/d-collar (4).png",
    "images/dogparts/d-shades/d-shade (4).png",
    "images/dogparts/d-eyes/d-e-eyes (1).png",
    "images/dogparts/d-head/d-e-head (1).png",
    "images/dogparts/d-back/d-e-back (1).png",
    "images/dogparts/d-mouth/d-e-mouth (1).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (1).png",
    "images/dogparts/d-eyes/d-e-eyes (2).png",
    "images/dogparts/d-head/d-e-head (2).png",
    "images/dogparts/d-back/d-e-back (2).png",
    "images/dogparts/d-mouth/d-e-mouth (2).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (2).png",
    "images/dogparts/d-eyes/d-e-eyes (3).png",
    "images/dogparts/d-head/d-e-head (3).png",
    "images/dogparts/d-back/d-e-back (3).png",
    "images/dogparts/d-mouth/d-e-mouth (3).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (3).png",
    "images/dogparts/d-eyes/d-e-eyes (4).png",
    "images/dogparts/d-head/d-e-head (4).png",
    "images/dogparts/d-back/d-e-back (4).png",
    "images/dogparts/d-mouth/d-e-mouth (4).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (4).png",
    "images/dogparts/d-eyes/d-e-eyes (5).png",
    "images/dogparts/d-head/d-e-head (5).png",
    "images/dogparts/d-back/d-e-back (5).png",
    "images/dogparts/d-mouth/d-e-mouth (5).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (5).png",
    "images/dogparts/d-eyes/d-e-eyes (6).png",
    "images/dogparts/d-head/d-e-head (6).png",
    "images/dogparts/d-back/d-e-back (6).png",
    "images/dogparts/d-mouth/d-e-mouth (6).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (6).png",
    "images/dogparts/d-eyes/d-e-eyes (7).png",
    "images/dogparts/d-head/d-e-head (7).png",
    "images/dogparts/d-back/d-e-back (7).png",
    "images/dogparts/d-mouth/d-e-mouth (7).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (7).png",
    "images/dogparts/d-eyes/d-e-eyes (8).png",
    "images/dogparts/d-head/d-e-head (8).png",
    "images/dogparts/d-back/d-e-back (8).png",
    "images/dogparts/d-mouth/d-e-mouth (8).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (8).png",
    "images/dogparts/d-eyes/d-e-eyes (9).png",
    "images/dogparts/d-head/d-e-head (9).png",
    "images/dogparts/d-back/d-e-back (9).png",
    "images/dogparts/d-mouth/d-e-mouth (9).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (9).png",
    "images/dogparts/d-eyes/d-e-eyes (10).png",
    "images/dogparts/d-head/d-e-head (10).png",
    "images/dogparts/d-back/d-e-back (10).png",
    "images/dogparts/d-mouth/d-e-mouth (10).png",
    "images/dogparts/d-legsfeet/d-e-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (5).png",
    "images/dogparts/mf-d-tail/m-d-tail (5).png",
    "images/dogparts/d-collar/d-collar (5).png",
    "images/dogparts/d-shades/d-shade (5).png",
    "images/dogparts/d-eyes/d-f-eyes (1).png",
    "images/dogparts/d-head/d-f-head (1).png",
    "images/dogparts/d-back/d-f-back (1).png",
    "images/dogparts/d-mouth/d-f-mouth (1).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (1).png",
    "images/dogparts/d-eyes/d-f-eyes (2).png",
    "images/dogparts/d-head/d-f-head (2).png",
    "images/dogparts/d-back/d-f-back (2).png",
    "images/dogparts/d-mouth/d-f-mouth (2).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (2).png",
    "images/dogparts/d-eyes/d-f-eyes (3).png",
    "images/dogparts/d-head/d-f-head (3).png",
    "images/dogparts/d-back/d-f-back (3).png",
    "images/dogparts/d-mouth/d-f-mouth (3).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (3).png",
    "images/dogparts/d-eyes/d-f-eyes (4).png",
    "images/dogparts/d-head/d-f-head (4).png",
    "images/dogparts/d-back/d-f-back (4).png",
    "images/dogparts/d-mouth/d-f-mouth (4).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (4).png",
    "images/dogparts/d-eyes/d-f-eyes (5).png",
    "images/dogparts/d-head/d-f-head (5).png",
    "images/dogparts/d-back/d-f-back (5).png",
    "images/dogparts/d-mouth/d-f-mouth (5).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (5).png",
    "images/dogparts/d-eyes/d-f-eyes (6).png",
    "images/dogparts/d-head/d-f-head (6).png",
    "images/dogparts/d-back/d-f-back (6).png",
    "images/dogparts/d-mouth/d-f-mouth (6).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (6).png",
    "images/dogparts/d-eyes/d-f-eyes (7).png",
    "images/dogparts/d-head/d-f-head (7).png",
    "images/dogparts/d-back/d-f-back (7).png",
    "images/dogparts/d-mouth/d-f-mouth (7).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (7).png",
    "images/dogparts/d-eyes/d-f-eyes (8).png",
    "images/dogparts/d-head/d-f-head (8).png",
    "images/dogparts/d-back/d-f-back (8).png",
    "images/dogparts/d-mouth/d-f-mouth (8).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (8).png",
    "images/dogparts/d-eyes/d-f-eyes (9).png",
    "images/dogparts/d-head/d-f-head (9).png",
    "images/dogparts/d-back/d-f-back (9).png",
    "images/dogparts/d-mouth/d-f-mouth (9).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (9).png",
    "images/dogparts/d-eyes/d-f-eyes (10).png",
    "images/dogparts/d-head/d-f-head (10).png",
    "images/dogparts/d-back/d-f-back (10).png",
    "images/dogparts/d-mouth/d-f-mouth (10).png",
    "images/dogparts/d-legsfeet/d-f-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (6).png",
    "images/dogparts/mf-d-tail/m-d-tail (6).png",
    "images/dogparts/d-collar/d-collar (6).png",
    "images/dogparts/d-shades/d-shade (6).png",
    "images/dogparts/d-eyes/d-g-eyes (1).png",
    "images/dogparts/d-head/d-g-head (1).png",
    "images/dogparts/d-back/d-g-back (1).png",
    "images/dogparts/d-mouth/d-g-mouth (1).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (1).png",
    "images/dogparts/d-eyes/d-g-eyes (2).png",
    "images/dogparts/d-head/d-g-head (2).png",
    "images/dogparts/d-back/d-g-back (2).png",
    "images/dogparts/d-mouth/d-g-mouth (2).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (2).png",
    "images/dogparts/d-eyes/d-g-eyes (3).png",
    "images/dogparts/d-head/d-g-head (3).png",
    "images/dogparts/d-back/d-g-back (3).png",
    "images/dogparts/d-mouth/d-g-mouth (3).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (3).png",
    "images/dogparts/d-eyes/d-g-eyes (4).png",
    "images/dogparts/d-head/d-g-head (4).png",
    "images/dogparts/d-back/d-g-back (4).png",
    "images/dogparts/d-mouth/d-g-mouth (4).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (4).png",
    "images/dogparts/d-eyes/d-g-eyes (5).png",
    "images/dogparts/d-head/d-g-head (5).png",
    "images/dogparts/d-back/d-g-back (5).png",
    "images/dogparts/d-mouth/d-g-mouth (5).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (5).png",
    "images/dogparts/d-eyes/d-g-eyes (6).png",
    "images/dogparts/d-head/d-g-head (6).png",
    "images/dogparts/d-back/d-g-back (6).png",
    "images/dogparts/d-mouth/d-g-mouth (6).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (6).png",
    "images/dogparts/d-eyes/d-g-eyes (7).png",
    "images/dogparts/d-head/d-g-head (7).png",
    "images/dogparts/d-back/d-g-back (7).png",
    "images/dogparts/d-mouth/d-g-mouth (7).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (7).png",
    "images/dogparts/d-eyes/d-g-eyes (8).png",
    "images/dogparts/d-head/d-g-head (8).png",
    "images/dogparts/d-back/d-g-back (8).png",
    "images/dogparts/d-mouth/d-g-mouth (8).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (8).png",
    "images/dogparts/d-eyes/d-g-eyes (9).png",
    "images/dogparts/d-head/d-g-head (9).png",
    "images/dogparts/d-back/d-g-back (9).png",
    "images/dogparts/d-mouth/d-g-mouth (9).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (9).png",
    "images/dogparts/d-eyes/d-g-eyes (10).png",
    "images/dogparts/d-head/d-g-head (10).png",
    "images/dogparts/d-back/d-g-back (10).png",
    "images/dogparts/d-mouth/d-g-mouth (10).png",
    "images/dogparts/d-legsfeet/d-g-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (7).png",
    "images/dogparts/mf-d-tail/m-d-tail (7).png",
    "images/dogparts/d-collar/d-collar (7).png",
    "images/dogparts/d-shades/d-shade (7).png",
    "images/dogparts/d-eyes/d-h-eyes (1).png",
    "images/dogparts/d-head/d-h-head (1).png",
    "images/dogparts/d-back/d-h-back (1).png",
    "images/dogparts/d-mouth/d-h-mouth (1).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (1).png",
    "images/dogparts/d-eyes/d-h-eyes (2).png",
    "images/dogparts/d-head/d-h-head (2).png",
    "images/dogparts/d-back/d-h-back (2).png",
    "images/dogparts/d-mouth/d-h-mouth (2).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (2).png",
    "images/dogparts/d-eyes/d-h-eyes (3).png",
    "images/dogparts/d-head/d-h-head (3).png",
    "images/dogparts/d-back/d-h-back (3).png",
    "images/dogparts/d-mouth/d-h-mouth (3).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (3).png",
    "images/dogparts/d-eyes/d-h-eyes (4).png",
    "images/dogparts/d-head/d-h-head (4).png",
    "images/dogparts/d-back/d-h-back (4).png",
    "images/dogparts/d-mouth/d-h-mouth (4).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (4).png",
    "images/dogparts/d-eyes/d-h-eyes (5).png",
    "images/dogparts/d-head/d-h-head (5).png",
    "images/dogparts/d-back/d-h-back (5).png",
    "images/dogparts/d-mouth/d-h-mouth (5).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (5).png",
    "images/dogparts/d-eyes/d-h-eyes (6).png",
    "images/dogparts/d-head/d-h-head (6).png",
    "images/dogparts/d-back/d-h-back (6).png",
    "images/dogparts/d-mouth/d-h-mouth (6).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (6).png",
    "images/dogparts/d-eyes/d-h-eyes (7).png",
    "images/dogparts/d-head/d-h-head (7).png",
    "images/dogparts/d-back/d-h-back (7).png",
    "images/dogparts/d-mouth/d-h-mouth (7).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (7).png",
    "images/dogparts/d-eyes/d-h-eyes (8).png",
    "images/dogparts/d-head/d-h-head (8).png",
    "images/dogparts/d-back/d-h-back (8).png",
    "images/dogparts/d-mouth/d-h-mouth (8).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (8).png",
    "images/dogparts/d-eyes/d-h-eyes (9).png",
    "images/dogparts/d-head/d-h-head (9).png",
    "images/dogparts/d-back/d-h-back (9).png",
    "images/dogparts/d-mouth/d-h-mouth (9).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (9).png",
    "images/dogparts/d-eyes/d-h-eyes (10).png",
    "images/dogparts/d-head/d-h-head (10).png",
    "images/dogparts/d-back/d-h-back (10).png",
    "images/dogparts/d-mouth/d-h-mouth (10).png",
    "images/dogparts/d-legsfeet/d-h-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (8).png",
    "images/dogparts/mf-d-tail/m-d-tail (8).png",
    "images/dogparts/d-collar/d-collar (8).png",
    "images/dogparts/d-shades/d-shade (8).png",
    "images/dogparts/d-eyes/d-i-eyes (1).png",
    "images/dogparts/d-head/d-i-head (1).png",
    "images/dogparts/d-back/d-i-back (1).png",
    "images/dogparts/d-mouth/d-i-mouth (1).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (1).png",
    "images/dogparts/d-eyes/d-i-eyes (2).png",
    "images/dogparts/d-head/d-i-head (2).png",
    "images/dogparts/d-back/d-i-back (2).png",
    "images/dogparts/d-mouth/d-i-mouth (2).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (2).png",
    "images/dogparts/d-eyes/d-i-eyes (3).png",
    "images/dogparts/d-head/d-i-head (3).png",
    "images/dogparts/d-back/d-i-back (3).png",
    "images/dogparts/d-mouth/d-i-mouth (3).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (3).png",
    "images/dogparts/d-eyes/d-i-eyes (4).png",
    "images/dogparts/d-head/d-i-head (4).png",
    "images/dogparts/d-back/d-i-back (4).png",
    "images/dogparts/d-mouth/d-i-mouth (4).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (4).png",
    "images/dogparts/d-eyes/d-i-eyes (5).png",
    "images/dogparts/d-head/d-i-head (5).png",
    "images/dogparts/d-back/d-i-back (5).png",
    "images/dogparts/d-mouth/d-i-mouth (5).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (5).png",
    "images/dogparts/d-eyes/d-i-eyes (6).png",
    "images/dogparts/d-head/d-i-head (6).png",
    "images/dogparts/d-back/d-i-back (6).png",
    "images/dogparts/d-mouth/d-i-mouth (6).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (6).png",
    "images/dogparts/d-eyes/d-i-eyes (7).png",
    "images/dogparts/d-head/d-i-head (7).png",
    "images/dogparts/d-back/d-i-back (7).png",
    "images/dogparts/d-mouth/d-i-mouth (7).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (7).png",
    "images/dogparts/d-eyes/d-i-eyes (8).png",
    "images/dogparts/d-head/d-i-head (8).png",
    "images/dogparts/d-back/d-i-back (8).png",
    "images/dogparts/d-mouth/d-i-mouth (8).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (8).png",
    "images/dogparts/d-eyes/d-i-eyes (9).png",
    "images/dogparts/d-head/d-i-head (9).png",
    "images/dogparts/d-back/d-i-back (9).png",
    "images/dogparts/d-mouth/d-i-mouth (9).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (9).png",
    "images/dogparts/d-eyes/d-i-eyes (10).png",
    "images/dogparts/d-head/d-i-head (10).png",
    "images/dogparts/d-back/d-i-back (10).png",
    "images/dogparts/d-mouth/d-i-mouth (10).png",
    "images/dogparts/d-legsfeet/d-i-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (9).png",
    "images/dogparts/mf-d-tail/m-d-tail (9).png",
    "images/dogparts/d-collar/d-collar (9).png",
    "images/dogparts/d-shades/d-shade (9).png",
    "images/dogparts/d-eyes/d-j-eyes (1).png",
    "images/dogparts/d-head/d-j-head (1).png",
    "images/dogparts/d-back/d-j-back (1).png",
    "images/dogparts/d-mouth/d-j-mouth (1).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (1).png",
    "images/dogparts/d-eyes/d-j-eyes (2).png",
    "images/dogparts/d-head/d-j-head (2).png",
    "images/dogparts/d-back/d-j-back (2).png",
    "images/dogparts/d-mouth/d-j-mouth (2).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (2).png",
    "images/dogparts/d-eyes/d-j-eyes (3).png",
    "images/dogparts/d-head/d-j-head (3).png",
    "images/dogparts/d-back/d-j-back (3).png",
    "images/dogparts/d-mouth/d-j-mouth (3).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (3).png",
    "images/dogparts/d-eyes/d-j-eyes (4).png",
    "images/dogparts/d-head/d-j-head (4).png",
    "images/dogparts/d-back/d-j-back (4).png",
    "images/dogparts/d-mouth/d-j-mouth (4).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (4).png",
    "images/dogparts/d-eyes/d-j-eyes (5).png",
    "images/dogparts/d-head/d-j-head (5).png",
    "images/dogparts/d-back/d-j-back (5).png",
    "images/dogparts/d-mouth/d-j-mouth (5).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (5).png",
    "images/dogparts/d-eyes/d-j-eyes (6).png",
    "images/dogparts/d-head/d-j-head (6).png",
    "images/dogparts/d-back/d-j-back (6).png",
    "images/dogparts/d-mouth/d-j-mouth (6).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (6).png",
    "images/dogparts/d-eyes/d-j-eyes (7).png",
    "images/dogparts/d-head/d-j-head (7).png",
    "images/dogparts/d-back/d-j-back (7).png",
    "images/dogparts/d-mouth/d-j-mouth (7).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (7).png",
    "images/dogparts/d-eyes/d-j-eyes (8).png",
    "images/dogparts/d-head/d-j-head (8).png",
    "images/dogparts/d-back/d-j-back (8).png",
    "images/dogparts/d-mouth/d-j-mouth (8).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (8).png",
    "images/dogparts/d-eyes/d-j-eyes (9).png",
    "images/dogparts/d-head/d-j-head (9).png",
    "images/dogparts/d-back/d-j-back (9).png",
    "images/dogparts/d-mouth/d-j-mouth (9).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (9).png",
    "images/dogparts/d-eyes/d-j-eyes (10).png",
    "images/dogparts/d-head/d-j-head (10).png",
    "images/dogparts/d-back/d-j-back (10).png",
    "images/dogparts/d-mouth/d-j-mouth (10).png",
    "images/dogparts/d-legsfeet/d-j-legsfeet (10).png",
    "images/dogparts/mf-d-tail/f-d-tail (10).png",
    "images/dogparts/mf-d-tail/m-d-tail (10).png",
    "images/dogparts/d-collar/d-collar (10).png",
    "images/dogparts/d-shades/d-shade (10).png"
];