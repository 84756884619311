import OwlCarousel from 'react-owl-carousel';
import { Link } from "react-router-dom"
const options = {
    autoplay:true,
    autoplaySpeed:2000,
    autoplayTimeout:2000,
    loop:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:3
        }
    }
}

function Showcase(){
    return ( <section className="nft-showcase">
    <div className="container text-center">
      <p className="text-center bcp-title py-4">Featured Dogs</p>
      <OwlCarousel items={3} nav  margin={8} {...options} className="owl-carousel owl-controls">
        <div className="item">
          <img className="owl-ns-img" src="images/showcase (1).png" alt="" />
        </div>
        <div className="item">
          <img className="owl-ns-img" src="images/showcase (2).png" alt="" />
        </div>
        <div className="item">
          <img className="owl-ns-img" src="images/showcase (3).png" alt="" />
        </div>
        <div className="item">
          <img className="owl-ns-img" src="images/showcase (4).png" alt="" />
        </div>
        <div className="item">
          <img className="owl-ns-img" src="images/showcase (5).png" alt="" />
        </div>
        <div className="item">
          <img className="owl-ns-img" src="images/showcase (6).png" alt="" />
        </div><div className="item">
          <img className="owl-ns-img" src="images/showcase (7).png" alt="" />
        </div><div className="item">
          <img className="owl-ns-img" src="images/showcase (8).png" alt="" />
        </div><div className="item">
          <img className="owl-ns-img" src="images/showcase (9).png" alt="" />
        </div>
        </OwlCarousel>
      <Link to="/marketplace" className="blockchainpets-btn blockchainpets-btn-wide">Explore More Dog NFTs</Link>
    </div>
  </section>)
}

export default Showcase;