import BreedableDog from "./BreedableDog";
import Dogsale from "./Dogsale";
import { useState,useEffect } from 'react'
import {user,instance,web3,contractAddress} from '../App';
import $ from 'jquery';
var fatherID;
var motherID;
function SelectDog(dog){
  if($(".dog-selection."+dog).hasClass("gender1")){
    $(".gender1").removeClass("border-blue")
    $(".dog-selection."+dog).addClass("border-blue");
    fatherID = dog.split('-')[0].slice(11,dog.length);
  }else{
    $(".gender2").removeClass("border-blue")
    $(".dog-selection."+dog).addClass("border-blue");
    motherID = dog.split('-')[0].slice(11,dog.length);
  }
 
}


function Breeding() {
  
async function breedDog(){
    instance.methods.Breeding(fatherID,motherID).send({},function(err,txHash){
      if(err){
        alert("Select Dogs Properly")
      } else{
        console.log("Dogs bred on txHash " + txHash);
      }
    });
    await instance.events.Birth().on('data',function(event){
      var dogId = event.returnValues.DogId;
      var genes = event.returnValues.geneSequence;
      window.$("#breed-outcome").modal('show')
      $('#dogBirthText').text("Dog created with ID: " + dogId + " and Gene Sequence: " + genes+ " ");  
      getUserNFTs();
  }  )

  }
  var [tokensOfOwner, setTokensOfOwner] = useState([]); 
  var [geneSequences, setGeneSequences] = useState([]); 
  var [geneSequenceandID, setGeneSequenceandID] = useState([]); 
  var [geneSequenceandIDmale, setGeneSequenceandIDmale] = useState([]); 
  var [geneSequenceandIDfemale, setGeneSequenceandIDfemale] = useState([]); 
  
  async function getUserNFTs(){
    if(instance !=null){
      tokensOfOwner = await instance.methods.tokensOfOwner(user).call()
      // const totalSupply = await instance.methods.totalSupply().call()
      geneSequences = [];
      for(const token of tokensOfOwner){
        geneSequences.push(await instance.methods.getDog(token).call())
      }
      geneSequenceandID= [];
      for(var i = 0; i < tokensOfOwner.length; i++){
        geneSequenceandID.push(geneSequences[i].geneSequence + tokensOfOwner[i] + "-" + geneSequences[i].generation);
      }
      geneSequenceandIDmale = []
      geneSequenceandIDfemale = []
      for(var i = 0; i<geneSequenceandID.length;i++){
        if(geneSequenceandID[i].slice(0,1) == 1){
          geneSequenceandIDmale.push(geneSequenceandID[i]);
        }else{
          geneSequenceandIDfemale.push(geneSequenceandID[i])
        }
      }
      setTokensOfOwner(tokensOfOwner)
      setGeneSequences(geneSequences)
      setGeneSequenceandID(geneSequenceandID)
      setGeneSequenceandIDmale(geneSequenceandIDmale)
      setGeneSequenceandIDfemale(geneSequenceandIDfemale)
      
      console.log(geneSequences);
      console.log(tokensOfOwner);
      console.log(geneSequenceandID)
    }
    
  }
  
  useEffect(()=>{
    
    getUserNFTs();
  },[])
  return (
    <section className="breeding-section">
      <p className="text-center bcp-title pt-0 pb-5">NFT Breeding</p>
      <div className="breeding-container container">
        <div className="row">
          <div className="col-md-6 pr-4">
            <p className="snft-p1 text-center">
              Select <span className="text-primary">Male</span>{" "}
            </p>
            <div className="row pt-0">
            {geneSequenceandIDmale.map( dog => (
          (<div className="col-md-6 col-lg-6 mt-5 " key={dog} >
            <div className={"dog-selection "+dog+ " gender"+dog.slice(0,1)} onClick={() => SelectDog(dog)}></div>
          <Dogsale srcurl='images/dog3.png' geneSequenceandID={dog.split('-')[0]} generation={dog.split('-')[1]} />
          </div>)
          ))}
            </div>
          </div>
          <div className="col-md-6 breeding-col-right pl-4">
            <p className="snft-p1 text-center">
              Select <span className="text-pink">Female</span>
            </p>
            <div className="row pt-0">
            {geneSequenceandIDfemale.map( dog => (
          (<div className="col-md-6 col-lg-6 mt-5" key={dog}>
            <div className={"dog-selection "+dog+ " gender"+dog.slice(0,1)} onClick={() => SelectDog(dog)}></div>
          <Dogsale srcurl='images/dog3.png' geneSequenceandID={dog.split('-')[0]} generation={dog.split('-')[1]}/>
          </div>)
          ))}
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={() => breedDog()}
          className="btn btn-lg btn-primary btn-block mt-5"
        >
          BREED DOGS
        </button>
        <div
          className="modal fade"
          id="breed-outcome"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="breed-outcome">
                  Breeding Result! 🥳
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                     <p id="dogBirthText"></p>
                    </div>
                </div>
               
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Breeding;
