function ConnectionModal(){
    return( <div
        className="modal fade"
        id="connect-wallet-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect-wallet-modal">
               Create Account For Wallet <span className="text-primary">0x01D02852F19Ddb5d7478498E8d58bb8161B04746</span> 
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="fname">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fname"
                    placeholder="Full Name"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="p-pic">Profile Picture</label>
                  <input
                    type="file"
                  className="d-block"
                    id="p-pic"
                    
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Create Account
              </button>
            </div>
          </div>
        </div>
      </div>)
}
export default ConnectionModal;