import DogForSale from "./DogForSale";
import $ from 'jquery';
import { useState,useEffect } from 'react'
import {user,instance,web3,contractAddress,connectWallet, headCheck} from '../App';
import { collars,legsfeet,eyes,colors,ears,mouths,shades, backs } from "./Createnft";
// async function start(){
//   await headCheck();
// }

function Marketplace(){
var [allTokensOnSale, setallTokensOnSale] = useState([]); 
var [geneSequencesSelling, setGeneSequencesSelling] = useState([]); 
async function getUserNFTs(){
  if(instance !=null){
    allTokensOnSale = await instance.methods.getAllTokenOnSale().call()
    geneSequencesSelling = [];
   
    for(const token of allTokensOnSale){
      var tokenOffer = await instance.methods.getOffer(token).call()
      // var owner = await instance.methods.ownerOf(token).call()
      // console.log(owner);
      if(tokenOffer.price != 0){
        console.log(web3.utils.fromWei(tokenOffer.price, 'ether'))
        var sequence = await instance.methods.getDog(token).call()
        sequence = sequence.geneSequence + token + "-" + sequence.generation  + "-" + web3.utils.fromWei(tokenOffer.price, 'ether');
        geneSequencesSelling.push(sequence)
      }
    }
    
    setallTokensOnSale(allTokensOnSale)
    setGeneSequencesSelling(geneSequencesSelling)
 
 
    console.log(geneSequencesSelling);
    console.log(allTokensOnSale);
  
  }
  
}

// async function filterNFTS(){

//     await filterAndUpdateInput()
// }
function testnum(id){
  var a = /^[+]?([.]\d+|\d+[.]?\d*)$/
      if(!a.test($(id).val())){
        $(id).val(0);
      }
    
}
async function getNFTS(){
  allTokensOnSale = await instance.methods.getAllTokenOnSale().call()
  geneSequencesSelling = [];
  setGeneSequencesSelling(geneSequencesSelling)
  for(const token of allTokensOnSale){
    var tokenOffer = await instance.methods.getOffer(token).call()
    // var owner = await instance.methods.ownerOf(token).call()
    // console.log(owner);
    if(tokenOffer.price != 0){
      console.log(web3.utils.fromWei(tokenOffer.price, 'ether'))
      var sequence = await instance.methods.getDog(token).call()
      sequence = sequence.geneSequence + token + "-" + sequence.generation  + "-" + web3.utils.fromWei(tokenOffer.price, 'ether');
      geneSequencesSelling.push(sequence)
    }
  }
}
async function filterAndUpdateInput(){
  $(".marketplace-loading").show();
  await getNFTS().then(_=>{
    searchNow();
    $(".marketplace-loading").hide();
  }) 
}

async function searchNow(){
  var structure =[ $("#eyeshape-search").val()+"-"+"eyeshape",
  $("#eyecolor-search").val()+"-"+"eyecolor",
  $("#head-search").val()+"-"+"head",
  $("#upperbodycolor-search").val()+"-"+"upperbodycolor",
  $("#lowerbodycolor-search").val()+"-"+"lowerbodycolor",
  $("#mouth-search").val()+"-"+"mouth",
  $("#back-search").val()+"-"+"back",
  $("#legsfeet-search").val()+"-"+"legsfeet",
  $("#collar-search").val()+"-"+"collar",
  $("#shades-search").val()+"-"+"shades"]
  
  
  if($("#gender").val() == 0 && $("#gen-search").val() == "any") {
    geneSequencesSelling = geneSequencesSelling.filter(x => +x.split('-')[2] >= $("#minprice").val() && +x.split('-')[2] <= $("#maxprice").val())
  } else if($("#gender").val() == 0){
    geneSequencesSelling = geneSequencesSelling.filter(x => x.split('-')[1] == $("#gen-search").val() && +x.split('-')[2] >= $("#minprice").val()&& +x.split('-')[2] <= $("#maxprice").val())

  }else if($("#gender").val() != 0 && $("#gen-search").val() == "any"){
    geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(0,1) == $("#gender").val() && +x.split('-')[2] >= $("#minprice").val()&& +x.split('-')[2] <= $("#maxprice").val())

  }else{
    geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(0,1) == $("#gender").val() && x.split('-')[1] == $("#gen-search").val() && +x.split('-')[2] >= $("#minprice").val()&& +x.split('-')[2] <= $("#maxprice").val())

  }

  structure.forEach(feature => {
     if(feature.split('-')[1] == "eyeshape" || feature.split('-')[1] == "eyecolor"){
       if($("#eyecolor-search").val() == "any" && $("#eyeshape-search").val() == "any"){
         $("#eyeshape-search-img").attr("src","images/dogparts/d-eyes/d-c-eyes (1).png")
         $("#eyecolor-search-img").attr("src","images/dogparts/d-eyes/d-c-eyes (1).png")
       }
       else if($("#eyecolor-search").val() == "any"){
         $("#eyeshape-search-img").attr("src","images/dogparts/d-eyes/"+eyes[$("#eyeshape-search").val()]+" (1).png")
         $("#eyecolor-search-img").attr("src","images/dogparts/d-eyes/"+eyes[$("#eyeshape-search").val()]+" (1).png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(1,2) == $("#eyeshape-search").val())
       }else if($("#eyeshape-search").val() == "any"){
         $("#eyeshape-search-img").attr("src","images/dogparts/d-eyes/d-c-eyes"+ colors[$("#eyecolor-search").val()] +".png")
         $("#eyecolor-search-img").attr("src","images/dogparts/d-eyes/d-c-eyes"+ colors[$("#eyecolor-search").val()] +".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(2,3) == $("#eyecolor-search").val())
       }else{
         $("#eyeshape-search-img").attr("src","images/dogparts/d-eyes/"+eyes[$("#eyeshape-search").val()]+ colors[$("#eyecolor-search").val()] +".png")
         $("#eyecolor-search-img").attr("src","images/dogparts/d-eyes/"+eyes[$("#eyeshape-search").val()]+ colors[$("#eyecolor-search").val()] +".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(2,3) == $("#eyecolor-search").val() && x.slice(1,2) == $("#eyeshape-search").val())
       }
     }
     if(feature.split('-')[1] == "back" || feature.split('-')[1] == "upperbodycolor" ){
       if($("#upperbodycolor-search").val() == "any" && $("#back-search").val() == "any"){
         $("#back-search-img").attr("src","images/dogparts/d-back/d-a-back (1).png")
         $("#upperbodycolor-search-img").attr("src","images/dogparts/d-back/d-a-back (1).png")
       }
       else if($("#upperbodycolor-search").val() == "any"){
         $("#back-search-img").attr("src","images/dogparts/d-back/"+backs[$("#back-search").val()]+" (1).png")
         $("#upperbodycolor-search-img").attr("src","images/dogparts/d-back/"+backs[$("#back-search").val()]+" (1).png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(7,8) == $("#back-search").val())
       }else if($("#back-search").val() == "any"){
         $("#back-search-img").attr("src","images/dogparts/d-back/d-a-back"+ colors[$("#upperbodycolor-search").val()] +".png")
         $("#upperbodycolor-search-img").attr("src","images/dogparts/d-back/d-a-back"+ colors[$("#upperbodycolor-search").val()] +".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(4,5) == $("#upperbodycolor-search").val())
       }else{
         $("#back-search-img").attr("src","images/dogparts/d-back/"+backs[$("#back-search").val()]+ colors[$("#upperbodycolor-search").val()] +".png")
         $("#upperbodycolor-search-img").attr("src","images/dogparts/d-back/"+backs[$("#back-search").val()]+ colors[$("#upperbodycolor-search").val()] +".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(7,8) == $("#back-search").val() && x.slice(4,5) == $("#upperbodycolor-search").val())
       }
     }
     if(feature.split('-')[1] == "legsfeet" || feature.split('-')[1] == "lowerbodycolor"){
       if($("#lowerbodycolor-search").val() == "any" && $("#legsfeet-search").val() == "any"){
         $("#legsfeet-search-img").attr("src","images/dogparts/d-legsfeet/d-a-legsfeet (1).png")
         $("#lowerbodycolor-search-img").attr("src","images/dogparts/d-legsfeet/d-a-legsfeet (1).png")
       }
       else if($("#lowerbodycolor-search").val() == "any"){
         $("#legsfeet-search-img").attr("src","images/dogparts/d-legsfeet/"+legsfeet[$("#legsfeet-search").val()]+" (1).png")
         $("#lowerbodycolor-search-img").attr("src","images/dogparts/d-legsfeet/"+legsfeet[$("#legsfeet-search").val()]+" (1).png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(8,9) == $("#legsfeet-search").val())
       }else if($("#legsfeet-search").val() == "any"){
         $("#legsfeet-search-img").attr("src","images/dogparts/d-legsfeet/d-a-legsfeet"+ colors[$("#lowerbodycolor-search").val()] +".png")
         $("#lowerbodycolor-search-img").attr("src","images/dogparts/d-legsfeet/d-a-legsfeet"+ colors[$("#lowerbodycolor-search").val()] +".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(5,6) == $("#lowerbodycolor-search").val())
       }else{
         $("#legsfeet-search-img").attr("src","images/dogparts/d-legsfeet/"+legsfeet[$("#legsfeet-search").val()]+ colors[$("#lowerbodycolor-search").val()] +".png")
         $("#lowerbodycolor-search-img").attr("src","images/dogparts/d-legsfeet/"+legsfeet[$("#legsfeet-search").val()]+ colors[$("#lowerbodycolor-search").val()] +".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(8,9) == $("#legsfeet-search").val() && x.slice(5,6) == $("#lowerbodycolor-search").val())
       }
     }
     if(feature.split('-')[1] == "head" || feature.split('-')[1] == "upperbodycolor"){
       if($("#head-search").val() == "any" && $("#upperbodycolor-search").val() == "any"){
         $("#head-search-img").attr("src","images/dogparts/d-head/d-a-head (1).png")
       }
       else if($("#head-search").val() == "any"){
         $("#head-search-img").attr("src","images/dogparts/d-head/d-a-head"+colors[$("#upperbodycolor-search").val()]+".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(4,5) == $("#upperbodycolor-search").val())
       }else if($("#upperbodycolor-search").val() == "any"){
         $("#head-search-img").attr("src","images/dogparts/d-head/"+ears[$("#head-search").val()]+" (1).png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(3,4) == $("#head-search").val())
       }else{
         $("#head-search-img").attr("src","images/dogparts/d-head/"+ears[$("#head-search").val()]+ colors[$("#upperbodycolor-search").val()] +".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(3,4) == $("#head-search").val() && x.slice(4,5) == $("#upperbodycolor-search").val())
       }
     }
     if(feature.split('-')[1] == "mouth" || feature.split('-')[1] == "lowerbodycolor"){
       if($("#mouth-search").val() == "any" && $("#lowerbodycolor-search").val() == "any"){
         $("#mouth-search-img").attr("src","images/dogparts/d-mouth/d-a-mouth (1).png")
       }
       else if($("#mouth-search").val() == "any"){
         $("#mouth-search-img").attr("src","images/dogparts/d-mouth/d-a-mouth"+colors[$("#lowerbodycolor-search").val()]+".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(6,7) == x.slice(5,6) == $("#lowerbodycolor-search").val())
       }else if($("#lowerbodycolor-search").val() == "any"){
         $("#mouth-search-img").attr("src","images/dogparts/d-mouth/"+mouths[$("#mouth-search").val()]+" (1).png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(6,7) == $("#mouth-search").val())
       }else{
         $("#mouth-search-img").attr("src","images/dogparts/d-mouth/"+mouths[$("#mouth-search").val()]+ colors[$("#lowerbodycolor-search").val()] +".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(6,7) == $("#mouth-search").val() && x.slice(5,6) == $("#lowerbodycolor-search").val())
       }
     }
     if(feature.split('-')[1] == "collar"){
       if($("#collar-search").val() == "any"){
         $("#collar-search-img").attr("src","images/dogparts/d-collar/d-collar (1).png")
       }else{
         $("#collar-search-img").attr("src","images/dogparts/d-collar/"+collars[$("#collar-search").val()]+".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(9,10) == $("#collar-search").val())
       }
     }
     if(feature.split('-')[1] == "shades"){
       if($("#shades-search").val() == "any"){
         $("#shades-search-img").attr("src","images/dogparts/d-shades/d-shade (3).png")
       }else{
         $("#shades-search-img").attr("src","images/dogparts/d-shades/"+shades[$("#shades-search").val()]+".png")
         geneSequencesSelling = geneSequencesSelling.filter(x => x.slice(10,11) == $("#shades-search").val())
       }
     }
     
  })
  geneSequencesSelling= [...new Set(geneSequencesSelling)];
  setGeneSequencesSelling(geneSequencesSelling)
}
useEffect(()=>{
  getUserNFTs();
},[])


    return(
        <section className="container marketplace">
             <p className="text-center bcp-title py-0 pb-3">Marketplace</p>
             <div>
              <div id="search1">
        <div className="form-row">
    
    <div className="form-group col-md-3">
    <label htmlFor="gender">Gender</label>
    <select id="gender" className="form-control fc-marketplace " onChange={filterAndUpdateInput}>
         <option defaultValue="true" value={0}>All Genders</option>
        <option value={1}>Male</option>
        <option value={2}> Female</option>
        
      </select>
        </div>
        <div className="form-group col-md-3">
        <label htmlFor="gender">Generation</label>
    <select id="gen-search" className="form-control fc-marketplace " onChange={filterAndUpdateInput}>
        <option defaultValue="true" value="any" >All Generations</option>
        <option value="0">GEN 0</option>
        <option value="1">GEN 1</option>
        <option value="2">GEN 2</option>
        <option value="3">GEN 3</option>
        <option value="4">GEN 4</option>
        <option value="5">GEN 5</option>
        <option value="6">GEN 6</option>
      </select>
        </div>
       
        <div className="form-group col-md-3">
         <label htmlFor="maxprice">Max Price</label>
     <input onChange={filterAndUpdateInput} onInput={()=>testnum("#maxprice")} type="number" className="form-control fc-marketplace " id="maxprice" defaultValue={100}/>
     </div>
     <div className="form-group col-md-3">
         <label htmlFor="minprice">Min Price</label>
     <input onChange={filterAndUpdateInput} onInput={()=>testnum("#minprice")} type="number" className="form-control fc-marketplace " id="minprice" defaultValue={0}/>
     </div>
    </div>
    </div>
    <div id="search2">
         <div className="form-row align-items-center">
         <div className="form-group col-md-3">
         <label htmlFor="eyeshape-search" className="pt-3">Eye Shape</label>
         <img src="images/dogparts/d-eyes/d-a-eyes (1).png" alt="" id="eyeshape-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="eyeshape-search" id="eyeshape-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Eye Shapes</option>
              <option value="0">Belittle</option>
              <option value="1">Closed</option>
              <option value="2">Wide Opened</option>
              <option value="3">Look Down</option>
              <option value="4">Flowered</option>
              <option value="5">X Eyes</option>
              <option value="6">Angry</option>
              <option value="7">Sleepy</option>
              <option value="8">Look Away</option>
              <option value="9">Funny</option>
            </select>
          </div>
          <div className="form-group col-md-3">
         <label htmlFor="eyecolor-search" className="pt-3">Eye Color</label>
         <img src="images/dogparts/d-eyes/d-c-eyes (1).png" alt=""  id="eyecolor-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="eyecolor-search" id="eyecolor-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Eye Colors</option>
              <option value="0">Snowy White</option>
              <option value="1">Fluorescent Red</option>
              <option value="2">Shocking Blue</option>
              <option value="3">Cadmium Violet</option>
              <option value="4">Sunburst Yellow</option>
              <option value="5">Aggressive Baby Blue</option>
              <option value="6">Aragon Green</option>
              <option value="7">Star Sapphhire</option>
              <option value="8">Kingly Cloud</option>
              <option value="9">Pulp</option>
            </select>
          </div>
          <div className="form-group col-md-3">
         <label htmlFor="head-search" className="pt-3">Head</label>
         <img src="images/dogparts/d-head/d-a-head (1).png" alt=""  id="head-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="head-search" id="head-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Heads</option>
              <option value="0">Default</option>
              <option value="1">Angel</option>
              <option value="2">Gold Bangle</option>
              <option value="3">Hachimaki Headband</option>
              <option value="4">Police Cap</option>
              <option value="5">Ribbon Bow</option>
              <option value="6">Simple Bandana</option>
              <option value="7">Fedora Hat</option>
              <option value="8">Mohawk Hair</option>
              <option value="9">Black P Cap</option>
            </select>
          </div><div className="form-group col-md-3">
         <label htmlFor="upperbodycolor-search" className="pt-3">Upper Body Color</label>
         <img src="images/dogparts/d-back/d-a-back (1).png" alt=""  id="upperbodycolor-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="upperbodycolor-search" id="upperbodycolor-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Upper Body Colors</option>
              <option value="0">Sun Salutation</option>
              <option value="1">Autumn Gold</option>
              <option value="2">Momo Peach</option>
              <option value="3">Gravel</option>
              <option value="4">Dingley</option>
              <option value="5">Noble Black</option>
              <option value="6">Deepest Sea</option>
              <option value="7">Kuro Brown</option>
              <option value="8">Mocha Foam</option>
              <option value="9">Cold Morning</option>
            </select>
          </div>

         </div>
         <div className="form-row align-items-center">
         <div className="form-group col-md-3">
         <label htmlFor="lowerbodycolor-search" className="pt-3">Lower Body Color</label>
         <img src="images/dogparts/d-legsfeet/d-a-legsfeet (1).png" alt=""  id="lowerbodycolor-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="lowerbodycolor-search" id="lowerbodycolor-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Lower Body Colors</option>
              <option value="0">Beige</option>
              <option value="1">Berber</option>
              <option value="2">Tint of Rose</option>
              <option value="3">Velied Spotlight</option>
              <option value="4">Modern Zen</option>
              <option value="5">Cereberal Grey</option>
              <option value="6">Icy Landscape</option>
              <option value="7">Turtle Dove</option>
              <option value="8">Soft Gossamer</option>
              <option value="9">Whiteout</option>
            </select>
          </div>
          <div className="form-group col-md-3">
         <label htmlFor="mouth-search" className="pt-3">Mouth</label>
         <img src="images/dogparts/d-mouth/d-a-mouth (1).png" alt=""  id="mouth-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="mouth-search" id="mouth-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Mouths</option>
              <option value="0">Happy</option>
              <option value="1">Closed</option>
              <option value="2">Hanging Tongue</option>
              <option value="3">Drooling</option>
              <option value="4">Drooling Tongue</option>
              <option value="5">Masculine</option>
              <option value="6">Serious</option>
              <option value="7">Thirsty</option>
              <option value="8">Holding Rose</option>
              <option value="9">Smoking</option>
            </select>
          </div>
          <div className="form-group col-md-3">
         <label htmlFor="back-search" className="pt-3">Back</label>
         <img src="images/dogparts/d-back/d-a-back (1).png" alt=""  id="back-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="back-search" id="back-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Backs</option>
              <option value="0">Default</option>
              <option value="1">Leather Sling Bag</option>
              <option value="2">Messenger Bag</option>
              <option value="3">Hike Backpack</option>
              <option value="4">Superhero Cape</option>
              <option value="5">Drinks Bag</option>
              <option value="6">Toolkit</option>
              <option value="7">Compact Bag</option>
              <option value="8">First Aid Kit</option>
              <option value="9">Samurai Sword</option>
            </select>
          </div><div className="form-group col-md-3">
         <label htmlFor="legsfeet-search" className="pt-3">Legs & Feet</label>
         <img src="images/dogparts/d-legsfeet/d-a-legsfeet (1).png" alt=""  id="legsfeet-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="legsfeet-search" id="legsfeet-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Legs & Feets</option>
              <option value="0">Default</option>
              <option value="1">Warm Socks</option>
              <option value="2">Firefighter Boots</option>
              <option value="3">Bandage</option>
              <option value="4">Rain Boots</option>
              <option value="5">Ice Skates</option>
              <option value="6">Roller Skates</option>
              <option value="7">Cowboy Boots</option>
              <option value="8">Winter Boots</option>
              <option value="9">Colorful Socks</option>
            </select>
          </div>

         </div>
         <div className="form-row align-items-center justify-content-center">
         <div className="form-group col-md-3">
         <label htmlFor="collar-search" className="pt-3">Collar</label>
         <img src="images/dogparts/d-collar/d-collar (1).png" alt=""  id="collar-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="collar-search" id="collar-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Collars</option>
              <option value="0">Gold Medal</option>
              <option value="1">Sunflower</option>
              <option value="2">Weed Leaf</option>
              <option value="3">Gold Bow</option>
              <option value="4">Bone</option>
              <option value="5">Studded</option>
              <option value="6">Silver Medal</option>
              <option value="7">Blooming Rose</option>
              <option value="8">Tri Star</option>
              <option value="9">Red & Blue Pattern</option>
            </select>
          </div>
          <div className="form-group col-md-3">
         <label htmlFor="shades-search" className="pt-3">Shades</label>
         <img src="images/dogparts/d-shades/d-shade (2).png" alt=""  id="shades-search-img" className="inputimg"/>
            <select onChange={filterAndUpdateInput} name="shades-search" id="shades-search" className="form-control fc-marketplace ">
              <option defaultValue="true" value="any">All Shades</option>
              <option value="0">No Shades</option>
              <option value="1">3D</option>
              <option value="2">Sunglasses</option>
              <option value="3">Robber Eye Mask</option>
              <option value="4">Full Shiled</option>
              <option value="5">Robot</option>
              <option value="6">Thug Life</option>
              <option value="7">Splash</option>
              <option value="8">Prince</option>
              <option value="9">Aviators</option>
            </select>
          </div>
          
          </div>
         </div>
         {/* <button className="btn btn-primary btn-block text-center"> Search</button> */}
         </div>
         <div className="marketplace-loading">
         <div className="d-flex justify-content-center align-items-center pt-5 rotate">
            <img src="images/logoicon.png" alt="" />
          </div>
         </div>
        
        <div className="row pt-4 pb-5">
          
        {
            geneSequencesSelling.map( dog => (
          (<div className="col-md-6 col-lg-4 mt-5" key={dog}> 
          <DogForSale srcurl='images/dog3.png' geneSequenceandID={dog.split('-')[0]} generation={dog.split('-')[1]} price={dog.split('-')[2]}/>
          </div>)
          ))
        
          }
            
        </div>
        {/* <ul className="pagination text-center py-5 justify-content-center">
            <li className="page-item"><a className="page-link" href="#">Previous</a></li>
            <li className="page-item active"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item"><a className="page-link" href="#">Next</a></li>
        </ul> */}
        
        </section>
        
    )
}
export default Marketplace;