import React from 'react';
import ReactDOM from 'react-dom/client';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

 
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <App />
);

reportWebVitals();
