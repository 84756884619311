import axios from "axios"
import $ from 'jquery';
import { useState,useEffect } from 'react'
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from 'react'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

function exportPDF(){
  var doc = new jsPDF();
    doc.autoTable({html: '#ru-table'});
    doc.save('RegisteredUsers.pdf');
}

function RegisteredUsers(){
  const tableRef = useRef(null);
	
	const { onDownload } = useDownloadExcel({
		currentTableRef: tableRef.current,
		filename: "Registered Users",
		sheet: "Users"
	});
    const [infoget,setinfoget] = useState([]);
    function getSubmissions(){
        axios.get('https://blockchainpets.abdulhananjavaid.com/bcpapi/userinfo.php').then(function(response){
            console.log(response.data);
            setinfoget(response.data);
          })
          
      }
      var count = 0;
      function counter(){
        return count++
      }

useEffect(()=>{
  getSubmissions();
},[])
    return(
    <section>
         <button className="btn-success btn mb-3" onClick={onDownload}>Export Excel</button>
        <button className="btn-danger btn mb-3 ml-3" onClick={exportPDF}>Export PDF</button>
        <div className="table-container table-bg-white">
          <table className="table table-hover" ref={tableRef} id="ru-table">
          <tbody>
              <tr>
                <th scope="col">User #</th>
                <th scope="col">Full Name</th>
                <th scope="col">Email</th>
                <th scope="col">Wallet</th>
              </tr>
          
            
            {
                infoget.map((info,key) => {
                    counter()
                return(
                    <tr key={key}>
                    <th scope="row">{count}</th>
                    <td>{info.fullname}</td>
                    <td>{info.email}</td>
                    <td>{info.address}</td>   
                  </tr>
                  )}
                )
            }
             
             
              <tr></tr>
            </tbody>
          </table>
        </div>
      <div
              className="modal fade"
              id="admin-edit"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title text-dark" id="admin-edit">
                     Edit Information
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="fname" className="text-dark">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fname"
                          placeholder="Full Name"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="email" className="text-dark">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" className="btn btn-primary">
                      Update Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
      </section>
    );
}
export default RegisteredUsers;