import { Link } from "react-router-dom"
import Dogsale from "./Dogsale"
import axios from "axios"
import $ from 'jquery';
import { useState,useEffect } from 'react'
function Header(){
  const [email, setEmails] = useState({})
  const handleSubmit = (event) =>{
    event.preventDefault();
    if($("#email").val() == ""){
      alert("you must enter an email");
    }else{
      axios.post('https://blockchainpets.abdulhananjavaid.com/bcpapi/index.php',email)
      $('.alert').show()
    }
  
  } 
  const handleChange = (event) =>{
    const email = event.target.name;
    const value = event.target.value;
    setEmails(values => ({
      ...values, [email]:value
    }))
  }
  $('.close').click(function() {
    $('.alert').hide();
 })
    return (<section className="blockchainpetsheader position-relative">
    <div className="overlay-blockchainpets-header"></div>
    {/* <img src="https://i.imgur.com/mYA474m.png" className="blockchainpetsheader-img" alt="" /> */}
    <div className="container h-100">
      <div className="row h-100 align-items-center justify-content-between">
        <div className="col-xl-6">
          <p className="bcp-title1 text-white ts-header">
            Unique, Breedable & Cute Blockchain Pet Dogs!
          </p>
          <p className="bcp-subtitle">
            
          </p>
         <div className="subform-div">
                       <form onSubmit={handleSubmit}  className="sub-form">
              <input name="email" id="email" type="email" className="sf-input form-control" onChange={handleChange} placeholder="Enter Email For Updates"/>
              <button type="submit" className="sf-submit blockchainpets-btn mt-3">Submit <i className="fas fa-paper-plane"></i></button>
            </form>
            <div className = "alert alert-success alert-dismissable mt-3">
         <button type = "button" className = "close" aria-hidden = "true">
            ×
         </button>
         Success! Email Submitted.
      </div>
            </div>
        </div>
        <div className="col-xl-5">
          <video src="videos/NFTSclip.mp4" autoPlay muted loop playsInline id="showcase-video"></video>
        </div>
      </div>
    </div>
   
  </section>)
}
export default Header;