import axios from "axios"
import $ from 'jquery';
import { useState,useEffect } from 'react'
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from 'react'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

function exportPDF(){
  var doc = new jsPDF();
    doc.autoTable({html: '#cs-table'});
    doc.save('ContactSubmissions.pdf');
}
function ContactSubmissions() {
  const tableRef = useRef(null);
	
	const { onDownload } = useDownloadExcel({
		currentTableRef: tableRef.current,
		filename: "Contact Submissions",
		sheet: "Submissions"
	});
    const [submissions,setsubmissions] = useState([]);
    useEffect(()=>{
        getSubmissions();
    },[])

    function getSubmissions(){
        axios.get('https://blockchainpets.abdulhananjavaid.com/bcpapi/contact.php').then(function(response){
            console.log(response.data);
            setsubmissions(response.data);
        })
    }
    return (
      <section className="users-list">
        
          <button className="btn-success btn mb-3" onClick={onDownload}>Export Excel</button>
          <button className="btn-danger btn mb-3 ml-3" onClick={exportPDF}>Export PDF</button>
          <div className="table-container table-bg-white">
            <table className="table  table-hover" ref={tableRef} id="cs-table">
              
              <tbody>
                <tr className="d-flex">
                  <th className="user col-1" >User</th>
                  <th className="name col-2" >Name</th>
                  <th className="email col-3" >Email</th>
                  <th className="subject col-2" >Subject</th>
                  <th className="message col-4" >Message</th>
                </tr>
              
              
            {
                submissions.map((submission,key) => {
                return(
                <tr className="d-flex" key={key}>
                <th className="col-1">{submission.id}</th>
                <td className="col-2">{submission.fullname}</td>
                <td className="col-3">{submission.email}</td>
                <td className="col-2">{submission.subject}</td>
                <td className="col-4">
                {submission.message}
                </td>
              </tr>)}
                )
            }
                <tr></tr>
              </tbody>
            </table>
          </div>
       
        
                 
              
      </section>
    );
  }
  export default ContactSubmissions;
  