import './App.css';
import $ from 'jquery';

import Navbar from './Components/Navbar'
import React from 'react';
import Home from "./Components/Home"
import Footer from "./Components/Footer"
import Marketplace from "./Components/Marketplace"
import ContactSubmissions from "./Components/ContactSubmissions"
import Createnft from "./Components/Createnft"
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Nftdetails from './Components/Nftdetails';
import Contact from './Components/Contact';
import Sell from './Components/Sell';
import Profile from './Components/Profile';
import Breeding from './Components/Breeding';
import Adminlogin from './Components/Adminlogin';
import AdminPanel from './Components/AdminPanel';
import Web3 from 'web3';
import {abi} from './Components/abi'
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-252527520-1"; 
ReactGA.initialize(TRACKING_ID);
export var web3 = new Web3(Web3.givenProvider);
export var instance;
export var user;
export var contractAddress = "0x86b8d55A746b5DdE299de85Be1a8a3220ea547bf"
// export var contractAddress = "0xa694c9BFC15a24144b33d635E1B75Ee8eD98b0c8"


export function connectWallet(){
  window.ethereum.enable().then(function(accounts){
    instance = new web3.eth.Contract(abi, contractAddress,{from: accounts[0]})
    user = accounts[0];
    console.log(instance);
    console.log(instance.methods.name().call());
    console.log(instance.methods.symbol().call());
    
  })
}
export async function isConnected() {
  const accounts = await window.ethereum.request({method: 'eth_accounts'});       
  if (accounts.length) {
    user = accounts[0];
    instance = new web3.eth.Contract(abi, contractAddress,{from: accounts[0]})
  } else {
    user=null;
     console.log("Metamask is not connected");
  }
}
export async function headCheck(){
  await connectWallet()
  await isConnected()
}
$(function(){
  isConnected()
})

function App() {
  ReactGA.pageview(window.location.pathname)
  return ( 
  
<BrowserRouter>
    <Navbar/>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/marketplace" component={Marketplace}/>
          <Route path="/createnft" component={Createnft}/>
          <Route path="/nftdetails" component={Nftdetails}/>
          <Route path="/contact" component={Contact}/>
          <Route path="/sell" component={Sell}/>
          <Route path="/profile" component={Profile}/>
          <Route path="/breeding" component={Breeding}/>
          <Route path="/adminlogin" component={Adminlogin} />
        </Switch>
        <Footer/>
    </BrowserRouter>
  
   );
  }

export default App;