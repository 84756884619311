import {Link} from "react-router-dom"
function BreedableDog(props){
    return(
        <div className="blockchainpets-img-box blockchainpets-img-box-breed mt-xl-0 mt-3">
        <img src={props.srcurl} className="nft-img" alt="" />
        <div className="row px-3 py-2 justify-content-between align-items-center">
          <div className="col-3">
            <div className="row align-items-center">
              <div className="col-6">
                <img src="images/eth.png" className="eth-img" alt="" />
                </div>
                <div className=" col-6">
                <p className="avi-p1">#93</p>
                <p className="avi-p1 text-primary">0.0002ETH</p>
              </div>
            </div>
          </div>
          <div className="col-2 text-right">
          <Link className="gen-i" to="/#">Gen 0</Link>
          </div>
        </div>
      </div> 
    )
}
export default BreedableDog