import axios from "axios"
import $ from 'jquery';
import { useState,useEffect } from 'react'
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from 'react'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

function exportPDF(){
  var doc = new jsPDF();
    doc.autoTable({html: '#es-table'});
    doc.save('EmailsSubmitted.pdf');
}
function EmailsSubmitted() {
  
  const tableRef = useRef(null);
	
	const { onDownload } = useDownloadExcel({
		currentTableRef: tableRef.current,
		filename: "Emails",
		sheet: "emails"
	});
    const [emails,setemails] = useState([]);
    useEffect(()=>{
        getEmails();
    },[])

    function getEmails(){
        axios.get('https://blockchainpets.abdulhananjavaid.com/bcpapi/index.php').then(function(response){
            console.log(response.data);
            setemails(response.data);
        })
    }
    return (
      <section className="users-list">
        
          <button className="btn-success btn mb-3" onClick={onDownload}>Export Excel</button>
          <button className="btn-danger btn mb-3 ml-3" onClick={exportPDF}>Export PDF</button>
          <div className="table-container table-bg-white">
            <table className="table  table-hover" ref={tableRef} id="es-table">
            <tbody>
                <tr>
                  <th className="user" scope="col">id</th>
                  <th className="email"  scope="col">Email</th>
                </tr>
           
            
            {
                emails.map((email,key) => {
                return(
                <tr  key={key}>
                <th scope="row">{email.id}</th>
                
                <td>
                {email.email}
                </td>
              </tr>)}
                )
            }
                <tr></tr>
              </tbody>
            </table>
          </div>
       
        
                 
              
      </section>
    );
  }
  export default EmailsSubmitted;
  