
import {user,instance,web3,contractAddress} from '../App';
import $ from 'jquery';
import { useState,useEffect } from 'react'
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from 'react'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

function AllNFTs(){
    var count = 0;
      function counter(){
        return count++
      }
    function exportPDF(){
        var doc = new jsPDF();
          doc.autoTable({html: '#nft-table'});
          doc.save('allnfts.pdf');
      }
    const tableRef = useRef(null);
	
	const { onDownload } = useDownloadExcel({
		currentTableRef: tableRef.current,
		filename: "All NFTS",
		sheet: "nfts"
	});
    var [tokens,setalltokens] = useState([])
    async function getTokens(){
        tokens =[];
        console.log(instance)
        var allTokens = await instance.methods.totalSupply().call()
        for(var i = 1; i<=allTokens;i++){
            var thetoken = await instance.methods.getDog(i).call()
            if(thetoken.fatherId == 0){
                thetoken.fatherId = "No Father"
                thetoken.motherId = "No Mother"
            }
            tokens.push(thetoken)
        }
        setalltokens(tokens);
    }
    useEffect(()=>{
        getTokens();
      },[])
   return(
    <section>
         <button className="btn-success btn mb-3" onClick={onDownload}>Export Excel</button>
        <button className="btn-danger btn mb-3 ml-3" onClick={exportPDF}>Export PDF</button>
        <div className="table-container table-bg-white">
          <table className="table table-hover" ref={tableRef} id="nft-table">
          <tbody>
              <tr className='header-sticky'>
                <th scope="col">ID</th>
                <th scope="col">GeneSequence</th>
                <th scope="col">Father</th>
                <th scope="col">Mother</th>
                <th scope="col">Generation</th>

              </tr>
          
            
            {
                tokens.map((token,key) => {
                    counter()
                return(
                    <tr key={key}>
                    <th scope="row">{count}</th>
                    <td>{token.geneSequence}</td>
                    <td>{token.fatherId}</td>
                    <td>{token.motherId}</td>
                    <td>{token.generation}</td>
                     
                  </tr>
                  )}
                )
            }
             
             
              <tr></tr>
            </tbody>
          </table>
        </div>
      </section>
   )
}
export default AllNFTs;