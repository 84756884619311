import { Link } from "react-router-dom"
import $ from 'jquery';
import axios from "axios"
import { useState,useEffect } from 'react'
import AdminPanel from "./AdminPanel";

function Adminlogin(){
    $('.close').click(function() {
        $('.alert').hide();
     })
    // const [adminInfo,setadminInfo] = useState([]);
    // useEffect(()=>{
    //     handleSubmit;
    // },[])
    const[panel,setPanel] = useState([]);

    const  handleSubmit = async (event) =>{
        event.preventDefault();
        var pass = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode($("#Admin-password").val()))
        const hashArray = Array.from(new Uint8Array(pass))
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        console.log(hashHex);
        axios.get('https://blockchainpets.abdulhananjavaid.com/bcpapi/admin.php').then(function(response){
            response.data.forEach(info => {
                console.log(info);
                if(info.username == $("#Admin-username").val() && info.password ==  hashHex) {
                    console.log("success");
                   
                    setPanel([...panel, "AdminPanel"]);
                    $("#bcp-admin-login-container").hide()
                }else{
                    $('.alert').show()
                }
            })
        })
    }
    return(
        <section className="bcp-admin-login">
            <div id="bcp-admin-login-container">

            
            <p className="text-center bcp-title py-4 pb-5">Admin Login</p>
            
            <div className="row justify-content-center" >
                <div className="col-xl-4 col-md-6">
                <div className="bcp-al-wrapper">
                <div className = "alert alert-danger alert-dismissable mt-3">
         <button type = "button" className = "close" aria-hidden = "true">
            ×
         </button>
         Incorrect username/password
      </div>
                <form>
                    <div className="form-group">
                        <label htmlFor="Admin-username">Username</label>
                        <input name="username" type="text" className="form-control" id="Admin-username"  placeholder="Admin Username"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="Admin-password">Password</label>
                        <input name="password" type="password" className="form-control" id="Admin-password" placeholder="Admin Password"/>
                    </div>
                    <button type="submit" to="/admin" className="btn btn-primary" onClick={handleSubmit}>Admin Access</button>
                    </form>
                    </div>
                </div>
            </div>
            </div>
            {
                panel.map((result,key) => {
                return(
                <div key={key}>
                    <AdminPanel started={result}/>
                    
                </div>
                )
                }
                )}
        </section>
        
    )
}

export default Adminlogin;