import axios from "axios"
import $ from 'jquery';
import { useState,useEffect } from 'react'

function Contact(){
    const [contact, setContacts] = useState({})
  const handleSubmit = (event) =>{
    event.preventDefault();
    if($("#fullname").val() == "" || $("#message").val() == ""||$("#subject").val() == ""||$("#inputEmail4").val() == ""){
        alert("Please fill in all information")
    }else{
        
        axios.post('https://blockchainpets.abdulhananjavaid.com/bcpapi/contact.php',contact)
        $('.alert').show()
    }
   
  } 
  const handleChange = (event) =>{
    const contact = event.target.name;
    const value = event.target.value;
    setContacts(values => ({
      ...values, [contact]:value
    }))
  }
  $('.close').click(function() {
    $('.alert').hide();
 })
    return (
        <section className="contact-admin">
            <form className="container">
                <p className="ca-title">Contact Admin</p>
                <div className = "alert alert-success alert-dismissable mt-3">
         <button type = "button" className = "close" aria-hidden = "true">
            ×
         </button>
         Success! We will get back to you soon!
      </div>
                <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="fullname">Full Name</label>
                    <input onChange={handleChange}  type="text" className="form-control" id="fullname" name="fullname" placeholder="Full Name"/>
                    </div>
                    <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Email</label>
                    <input onChange={handleChange}  type="email" className="form-control" id="inputEmail4" name="email" placeholder="Email"/>
                </div>
                   
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="subject">Subject</label>
                        <input onChange={handleChange}  type="text" className="form-control" id="subject" name="subject" placeholder="Subject"/>
                        
                    
                    </div>
                   
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="message">Message</label>
                        <textarea onChange={handleChange} name="message" id="message" cols="30" rows="10" className="form-control"></textarea>
                        
                    
                    </div>
                   
                </div>

                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>

            </form>
        </section>
    )
}
export default Contact;