import $ from 'jquery';
import {user,instance,web3,contractAddress} from '../App';
import { collars,legsfeet,eyes,colors,ears,mouths,shades, backs } from "./Createnft";
import {generatetail, checkEyelashes} from "./Dogsale"

var collarsNames = [
    "Gold Medal",
    "Sunflower",
    "Weed Leaf",
    "Gold Bow",
    "Bone",
    "Studded",
    "Silver Medal",
    "Blooming Rose",
    "Tri Star",
    "Red & Blue Pattern",
]

var legsfeetNames = [
    "Default",
    "Warm Socks",
    "Firefighter Boots",
    "Bandage",
    "Rain Boots",
    "Ice Skates",
    "Roller Skates",
    "Cowboy Boots",
    "Winter Boots",
    "Colorful Socks",
]
var eyesNames = [
    "Belittle",
    "Closed",
    "Wide Opened",
    "Look Down",
    "Flowered",
    "X Eyes",
    "Angry",
    "Sleepy",
    "Look Away",
    "Funny",
]
var colorsUBNames = [
    "Sun Salutation",
    "Autumn Gold",
    "Momo Peach",
    "Gravel",
    "Dingley",
    "Noble Black",
    "Deepest Sea",
    "Kuro Brown",
    "Mocha Foam",
    "Cold Morning",
]
var colorsLBNames = [
    "Beige",
    "Berber",
    "Tint of Rose",
    "Velied Spotlight",
    "Modern Zen",
    "Cereberal Grey",
    "Icy Landscape",
    "Turtle Dove",
    "Soft Gossamer",
    "Whiteout",
]
var colorsENames = [
    "Snowy White",
    "Fluorescent Red",
    "Shocking Blue",
    "Cadmium Violet",
    "Sunburst Yellow",
    "Aggressive Baby Blue",
    "Aragon Green",
    "Star Sapphhire",
    "Kingly Cloud",
    "Pulp",
]
var earsNames = [
    "Default",
    "Angel",
    "Gold Bangle",
    "Hachimaki Headband",
    "Police Cap",
    "Ribbon Bow",
    "Simple Bandana",
    "Fedora Hat",
    "Mohawk Hair",
    "Black P Cap",
]
var mouthsNames = [
    "Happy",
    "Closed",
    "Hanging Tongue",
    "Drooling",
    "Drooling Tongue",
    "Masculine",
    "Serious",
    "Thirsty",
    "Holding Rose",
    "Smoking",
]
var shadesNames = [
    "No Shades",
    "3D",
    "Sunglasses",
    "Robber Eye Mask",
    "Full Shiled",
    "Robot",
    "Thug Life",
    "Splash",
    "Prince",
    "Aviators",
]
var  backsNames = [
    "Default",
    "Leather Sling Bag",
    "Messenger Bag",
    "Hike Backpack",
    "Superhero Cape",
    "Drinks Bag",
    "Toolkit",
    "Compact Bag",
    "First Aid Kit",
    "Samurai Sword",
]

function getGender(geneSequence){
    if(geneSequence.slice(0,1) == 1){
        return "Male"
    } else{
        return "Female"
    }
}

async function buyDog(id, price) {
    var amount = web3.utils.toWei(price, "ether")
    try {
      await instance.methods.buyDog(id).send({ value: amount });
    } catch (err) {
      console.log(err);
    }
  }
function nftdetails(props){
    instance.events.MarketTransaction()
    .on('data', (event) => {
      console.log(event);
      var eventName = event.returnValues["TxType"].toString()
      var tokenId = event.returnValues["tokenId"]
      console.log("testing: " + eventName + " and " + tokenId)
      if(eventName == "Buy"){
        window.$('.modal').modal('hide')
        window.$("#dog-listing").modal('show')
        $(".dog-listing-text").html('Dog Purchase Successful!')
      }
      
    })
    var geneSequenceandID =  props.location.query.geneSequenceandID
 
   
    var geneSequence
    var id
    var generation = props.location.query.generation
    console.log("gen " + generation);
    geneSequence =geneSequenceandID.slice(0,11);
    id = geneSequenceandID.slice(11,geneSequenceandID.length);
    var ownerAcc
   $(async function(){
    ownerAcc = await instance.methods.ownerOf(geneSequenceandID.slice(11,geneSequenceandID.length)).call()
    $(".nft-owner").html(ownerAcc)
    $(".nft-owner").attr("href","https://goerli.etherscan.io/address/" + ownerAcc)
    if(ownerAcc.toUpperCase()==user.toUpperCase()){
        $(".own-self").removeClass("d-none")
        $(".buy-nft-btn").addClass("d-none")
    }
   })
   if(props.location.query.price != null){
    var price = props.location.query.price;
  
    return (
        <section className="nft-details">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <div className="nft-details-container">
                        <div className="scdb-container-details">
                            <div className={"show-case-design show-case-design-box-details position-relative "+geneSequence}>
                                <img src={"images/dogparts/d-collar/"+ collars[geneSequence.slice(9,10)] + ".png"} alt="" id="d-collar"/>
                                <img src={"images/dogparts/d-head/" + ears[geneSequence.slice(3,4)] + colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-head"/>
                                <img src={"images/dogparts/d-eyes/" + eyes[geneSequence.slice(1,2)] + colors[geneSequence.slice(2,3)]  + ".png"} alt="" id="d-eyes" />
                                <img src={"images/dogparts/d-legsfeet/" + legsfeet[geneSequence.slice(8,9)] + colors[geneSequence.slice(5,6)] + ".png"} alt="" id="d-legsfeet" />
                                <img src={"images/dogparts/d-back/" + backs[geneSequence.slice(7,8)]+ colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-back" />
                                <img src={"images/dogparts/d-mouth/" + mouths[geneSequence.slice(6,7)] + colors[geneSequence.slice(5,6)] +".png"} alt="" id="d-mouth"/>
                                <img src={"images/dogparts/d-shades/" + shades[geneSequence.slice(10,11)] + ".png"} alt="" id="d-shades"/>
                                <img src={generatetail(geneSequence) + colors[geneSequence.slice(4,5)] +".png"} alt="" id="mf-d-tail" />
                                <img src={"images/dogparts/f-d-eyelashes/f-d-eyelashes.png"} alt="" id="d-eyelashes" className={checkEyelashes(geneSequence)}/>
                            </div>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Eye Shape</p>
                            <p className="detail-pill-p2">{eyesNames[geneSequence.slice(1,2)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Eye Color</p>
                            <p className="detail-pill-p2">{colorsENames[geneSequence.slice(2,3)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Head</p>
                            <p className="detail-pill-p2"> {earsNames[geneSequence.slice(3,4)]} </p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Legs & Feet</p>
                            <p className="detail-pill-p2">{legsfeetNames[geneSequence.slice(8,9)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Upper Body Color</p>
                            <p className="detail-pill-p2">{colorsUBNames[geneSequence.slice(4,5)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Lower Body Color</p>
                            <p className="detail-pill-p2">{colorsLBNames[geneSequence.slice(5,6)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Mouth</p>
                            <p className="detail-pill-p2">{mouthsNames[geneSequence.slice(6,7)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Back</p>
                            <p className="detail-pill-p2">{backsNames[geneSequence.slice(7,8)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Collar</p>
                            <p className="detail-pill-p2">{collarsNames[geneSequence.slice(9,10)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Shades</p>
                            <p className="detail-pill-p2">{shadesNames[geneSequence.slice(10,11)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Gender</p>
                            <p className="detail-pill-p2">{getGender(geneSequence)}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Generation</p>
                            <p className="detail-pill-p2">{generation}</p>
                        </div>
                        </div>
                        
                    </div>
                    <div className="col-md-5">
                        <div className="dog-detail-right">
                            
                            <div className="ddr-price">
                            <p className="ddr-p1">
                                Dog # {id}
                            </p>
                            <p className="ddr-p2">Current Owner: <a href="" className="nft-owner" target="_blank"> </a></p>
                            <p className="ddr-p2">Gene Sequence: {geneSequence}</p>
                                <p className="ddrp-p1">Listing Price</p>
                               <div className="d-flex align-items-center">
                               <img src="images/eth.png" alt="" />
                                <span className="ddrp-p2">{price}</span>
                               </div>
                               <div id='dog-buy-container'>
                               <button className="btn btn-primary btn-lg my-3 font-weight-bold buy-nft-btn" onClick={() => buyDog(id,price)}> <i className="fas fa-wallet"></i> BUY NFT</button>
                                    <p className="own-self d-none">You Own This NFT</p>
                               </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" tabIndex="-1" role="dialog" id='dog-listing'>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title dog-listing-text">Dog Listed For Sale!</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p id='dog-listing-text' className="dog-listing-text"></p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
            </div>
        </section>
    )
}else{
    return (
        <section className="nft-details">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <div className="nft-details-container">
                        <div className="scdb-container-details">
                            <div className={"show-case-design show-case-design-box-details position-relative "+geneSequence}>
                                <img src={"images/dogparts/d-collar/"+ collars[geneSequence.slice(9,10)] + ".png"} alt="" id="d-collar"/>
                                <img src={"images/dogparts/d-head/" + ears[geneSequence.slice(3,4)] + colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-head"/>
                                <img src={"images/dogparts/d-eyes/" + eyes[geneSequence.slice(1,2)] + colors[geneSequence.slice(2,3)]  + ".png"} alt="" id="d-eyes" />
                                <img src={"images/dogparts/d-legsfeet/" + legsfeet[geneSequence.slice(8,9)] + colors[geneSequence.slice(5,6)] + ".png"} alt="" id="d-legsfeet" />
                                <img src={"images/dogparts/d-back/" + backs[geneSequence.slice(7,8)]+ colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-back" />
                                <img src={"images/dogparts/d-mouth/" + mouths[geneSequence.slice(6,7)] + colors[geneSequence.slice(5,6)] +".png"} alt="" id="d-mouth"/>
                                <img src={"images/dogparts/d-shades/" + shades[geneSequence.slice(10,11)] + ".png"} alt="" id="d-shades"/>
                                <img src={generatetail(geneSequence) + colors[geneSequence.slice(4,5)] +".png"} alt="" id="mf-d-tail" />
                                <img src={"images/dogparts/f-d-eyelashes/f-d-eyelashes.png"} alt="" id="d-eyelashes" className={checkEyelashes(geneSequence)}/>
                            </div>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Eye Shape</p>
                            <p className="detail-pill-p2">{eyesNames[geneSequence.slice(1,2)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Eye Color</p>
                            <p className="detail-pill-p2">{colorsENames[geneSequence.slice(2,3)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Head</p>
                            <p className="detail-pill-p2"> {earsNames[geneSequence.slice(3,4)]} </p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Legs & Feet</p>
                            <p className="detail-pill-p2">{legsfeetNames[geneSequence.slice(8,9)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Upper Body Color</p>
                            <p className="detail-pill-p2">{colorsUBNames[geneSequence.slice(4,5)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Lower Body Color</p>
                            <p className="detail-pill-p2">{colorsLBNames[geneSequence.slice(5,6)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Mouth</p>
                            <p className="detail-pill-p2">{mouthsNames[geneSequence.slice(6,7)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Back</p>
                            <p className="detail-pill-p2">{backsNames[geneSequence.slice(7,8)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Collar</p>
                            <p className="detail-pill-p2">{collarsNames[geneSequence.slice(9,10)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Shades</p>
                            <p className="detail-pill-p2">{shadesNames[geneSequence.slice(10,11)]}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Gender</p>
                            <p className="detail-pill-p2">{getGender(geneSequence)}</p>
                        </div>
                        <div className="detail-pill d-inline-block">
                            <p className="detail-pill-p1">Generation</p>
                            <p className="detail-pill-p2">{generation}</p>
                        </div>
                        </div>
                        
                    </div>
                    <div className="col-md-5">
                        <div className="dog-detail-right">
                            
                            <div className="ddr-price">
                            <p className="ddr-p1">
                                Dog # {id}
                            </p>
                            <p className="ddr-p2">Current Owner: <a className="nft-owner" href="" target="_blank"> </a></p>
                            <p className="ddr-p2">Gene Sequence: {geneSequence}</p>
                                {/* <p className="ddrp-p1">Listing Price</p>
                               <div className="d-flex align-items-center">
                               <img src="images/eth.png" alt="" />
                                <span className="ddrp-p2">0.0002ETH</span>
                               </div>
                                <button className="btn btn-primary btn-lg my-3 font-weight-bold"> <i className="fas fa-wallet"></i> BUY NFT</button>
                               */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
    
}

export default nftdetails;