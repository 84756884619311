import { Link } from "react-router-dom"
import { NavLink } from "react-router-dom"
import {user} from "../App"
import { connectWallet,isConnected } from "../App";
import $ from 'jquery';
function renderConnectionState(){
  if(user == null){
    return (
      " Connect Wallet"
    )
  }else{
    return(
      user.slice(0,10) + "..."
    )
  }
  
}
function renderProfileState(){
  if(user == null){
    return (
      <li className="nav-item d-none" id="profile-btn">
          <NavLink exact activeClassName="bcp-active-nav-item" className="nav-link nav-profile" to="/profile"><i className="far fa-user-circle"></i></NavLink>
        </li>
    )
  }else{
    return(
      <li className="nav-item" id="profile-btn">
          <NavLink exact activeClassName="bcp-active-nav-item" className="nav-link nav-profile" to="/profile"><i className="far fa-user-circle"></i></NavLink>
        </li>
    )
  }
  
}
setInterval(function(){
  isConnected()
  if(user == null){
   
    $("#connection-state").html(" Connect Wallet")
    $("#profile-btn").addClass("d-none")
    
 
  }else{
    $("#profile-btn").removeClass("d-none")
      $("#connection-state").html(user.slice(0,10) + "...")
  }
},1000)

function Navbar(){
    return (<nav className="navbar navbar-expand-lg navbar-light sticky-top blockchainpets-navbar">
    <Link className="navbar-brand" to="/#">
      <img src="images/bcp-logo2.svg" alt="" />
    </Link>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button> 
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ml-auto align-items-center">
        <li className="nav-item">
          <NavLink exact activeClassName="bcp-active-nav-item" className="nav-link" to="/marketplace">MARKETPLACE</NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact activeClassName="bcp-active-nav-item" className="nav-link" to="/breeding">BREEDING</NavLink>
        </li>

        <li className="nav-item">
          <NavLink exact activeClassName="bcp-active-nav-item" className="nav-link" to="/createnft">CREATE</NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact activeClassName="bcp-active-nav-item" className="nav-link" to="/Sell">SELL</NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact activeClassName="bcp-active-nav-item" className="nav-link" to="/contact">CONTACT</NavLink>
        </li>
        {renderProfileState()}
        <li className="nav-item">
          <button type="button"
           onClick={connectWallet}
            className="connect-wallet-btn nav-link nav-wallet"><i className="fas fa-wallet"></i> 
             <span id="connection-state">{renderConnectionState()}</span> 
            </button>
          
        </li>
      </ul>
      
    </div>
    
  </nav>)
}
export default Navbar;