import { Link } from "react-router-dom";
import { collars,legsfeet,eyes,colors,ears,mouths,shades, backs } from "./Createnft";
import {generatetail, checkEyelashes} from "./Dogsale"
import $ from 'jquery';
import {user,instance,web3,contractAddress} from '../App';

async function sellDog(id, geneSequence) {  
  var price = $("#price"+ id + geneSequence).val()
  console.log(String(price));
  var amount = web3.utils.toWei(String(price), "ether")
  try {
    await instance.methods.setOffer(amount,id).send();
  } catch (err) {
    console.log(err);
  }
}




function Dogsell(props) {
 
  var geneSequenceandID = props.geneSequenceandID
  var generation = props.generation
  var geneSequence
  var id
    geneSequence = props.geneSequenceandID.slice(0,11);
    id = props.geneSequenceandID.slice(11,geneSequenceandID.length);
     function numOnly(id) {
      var a = /^[+]?([.]\d+|\d+[.]?\d*)$/
      if(!a.test($("#"+id).val())){
        $("#"+id).val("");
      }
      
    };
  return (
    <div className="blockchainpets-img-box mt-xl-0 mt-3">
     <div className="scdb-container">
    <div className={"show-case-design show-case-design-box position-relative "+geneSequence}>
          <img src={"images/dogparts/d-collar/"+ collars[geneSequence.slice(9,10)] + ".png"} alt="" id="d-collar"/>
          <img src={"images/dogparts/d-head/" + ears[geneSequence.slice(3,4)] + colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-head"/>
          <img src={"images/dogparts/d-eyes/" + eyes[geneSequence.slice(1,2)] + colors[geneSequence.slice(2,3)]  + ".png"} alt="" id="d-eyes" />
          <img src={"images/dogparts/d-legsfeet/" + legsfeet[geneSequence.slice(8,9)] + colors[geneSequence.slice(5,6)] + ".png"} alt="" id="d-legsfeet" />
          <img src={"images/dogparts/d-back/" + backs[geneSequence.slice(7,8)]+ colors[geneSequence.slice(4,5)] +".png"} alt="" id="d-back" />
          <img src={"images/dogparts/d-mouth/" + mouths[geneSequence.slice(6,7)] + colors[geneSequence.slice(5,6)] +".png"} alt="" id="d-mouth"/>
          <img src={"images/dogparts/d-shades/" + shades[geneSequence.slice(10,11)] + ".png"} alt="" id="d-shades"/>
          <img src={generatetail(geneSequence) + colors[geneSequence.slice(4,5)] +".png"} alt="" id="mf-d-tail" />
          <img src={"images/dogparts/f-d-eyelashes/f-d-eyelashes.png"} alt="" id="d-eyelashes" className={checkEyelashes(geneSequence)}/>
    </div>
    </div>
      <div className="row px-3 py-2 justify-content-between align-items-center">
        <div className="col-3">
          <div className="row align-items-center">
            <div className=" col-6">
              <p className="avi-p1">#{id}</p>
            </div>
          </div>
        </div>
        <div className=" col-6">
          <button
            type="button"
            data-toggle="modal"
            data-target={"#id" + id + geneSequence}
            className="btn btn-primary btn-block"
          >
            Sell NFT
          </button>
          <div
            className="modal fade"
            id={"id" + id + geneSequence}
            tabIndex="-1"
            role="dialog"
            aria-labelledby=""
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="sellmodalLabel">
                    Sell NFT #{id}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor={ "price" + id + geneSequence}>Listing Price</label>
                      <input
                        type="number"
                        className="form-control number-in"
                        id={"price" + id + geneSequence}
                        placeholder="Listing Price"
                        onInput={()=>numOnly("price" + id + geneSequence)}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={() => sellDog(id, geneSequence)}>
                    List For Sale
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 text-right">
          <Link className="gen-i" to="/#">
            Gen {props.generation}
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Dogsell;
